@import '../resources/vars';
@import '../resources/mixins';

.form-explainer {
  background: var(--app-custom-explainer-background, var(--forms-explainer-background));
  margin: var(--app-custom-explainer-margin, var(--forms-explainer-margin));
  padding: var(--app-custom-explainer-padding, var(--forms-explainer-padding));

  order: var(--app-custom-explainer-order, var(--forms-explainer-order));

  color: var(--app-custom-explainer-label-colour, var(--forms-explainer-label-colour));
  font-family: var(--app-custom-explainer-label-font-family, var(--forms-explainer-label-font-family));
  font-size: var(--app-custom-explainer-label-font-size, var(--forms-explainer-label-font-size));
  font-weight: var(--app-custom-explainer-label-font-weight, var(--forms-explainer-label-font-weight));
  font-style: var(--app-custom-explainer-label-font-style, var(--forms-explainer-label-font-style));
  line-height: var(--app-custom-explainer-label-line-height, var(--forms-explainer-label-line-height));
  letter-spacing: var(--app-custom-explainer-label-letter-spacing, var(--forms-explainer-label-letter-spacing));
  text-transform: var(--app-custom-explainer-label-text-transform, var(--forms-explainer-label-text-transform));
  text-align: var(--app-custom-explainer-label-text-align, var(--forms-explainer-label-text-align));

  @include respond-to(sm, up) {
    font-size: var(--app-custom-explainer-label-font-size-m, var(--forms-explainer-label-font-size-m));
  }

  @include respond-to(lg, up) {
    font-size: var(--app-custom-explainer-label-font-size-l, var(--forms-explainer-label-font-size-m));
  }
}
