@import '../resources/vars';
@import '../resources/mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  height: var(--app-custom-progress-bar-height, auto);
  width: var(--app-custom-progress-bar-width,100%);
  padding: var(--app-custom-progress-bar-padding);
  margin: var(--app-custom-progress-bar-margin);
  background: var(--app-custom-progress-bar-background, #dfe3e6);
  border: var(--app-custom-progress-bar-border);
  border-radius: var(--app-custom-progress-bar-border-radius, 4px);
  box-shadow: var(--app-custom-progress-bar-box-shadow);

}

.wrapper {
  position: relative;
}

.label {
  @include with-custom-styled-text(app-custom-progress-label, coach-bars-label);
  margin: var(--app-custom-progress-label-margin, var(--coach-bars-label-margin));
  padding: var(--app-custom-progress-label-padding, var(--coach-bars-label-padding));
}

.filler {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: var(--app-custom-progress-filler-background, #33396c);
  border: var(--app-custom-progress-filler-border);
  border-radius: inherit;
  //border-radius: var(--app-custom-progress-filler-border-radius);
  box-shadow: var(--app-custom-progress-filler-box-shadow);
  height: 100%;
  transition: width 1.5s ease-out;
}
