@import '../resources/vars';
@import '../resources/mixins';

.controller {
  padding: 0;
  border: none;
  margin: 0;

  display: flex;
  flex-direction: column;
  width: 100%;
}
