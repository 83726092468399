@import '../../../../apps/firsthomecoach/src/styles/resources/vars';
@import '../../../../apps/firsthomecoach/src/styles/resources/mixins';

.container {
	@extend %scroll-bar-reset;

	&.at-y {
		overflow-y: auto;
	}

	&.at-x {
		overflow-x: auto;
	}

	&.at-both {
		overflow: auto;
	}
}
