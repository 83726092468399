@import '../resources/vars';
@import '../resources/mixins';

.header {
  --app-custom-template-column-start: var(--app-template-question-header-column-start);
  --app-custom-template-column-end: var(--app-template-question-header-column-end);
}

.content {
  flex: 1;

  padding: var(--article-body-padding);

  @include respond-to(sm, up) {
    padding: var(--article-body-padding-m, var(--article-body-padding));
  }

  @include respond-to(lg, up) {
    padding: var(--article-body-padding-l, var(--article-body-padding));
  }
}

.grid {
  padding: var(--pt-feed-content-padding);

  --app-custom-grid-cols: var(--pt-feed-content-grid-template-columns, 2);
  --app-custom-grid-gap-row: var(--pt-feed-content-row-gap, 10px);
  --app-custom-grid-gap-column: var(--pt-feed-content-column-gap, 10px);

  @include respond-to(sm, up) {
    --app-custom-grid-cols: var(--pt-feed-content-grid-template-columns-m, var(--pt-feed-content-grid-template-columns));
    --app-custom-grid-gap-row: var(--pt-feed-content-row-gap-m, var(--pt-feed-content-row-gap));
    --app-custom-grid-gap-column: var(--pt-feed-content-column-gap-m, var(--pt-feed-content-column-gap));
  }

  @include respond-to(lg, up) {
    --app-custom-grid-cols: var(--pt-feed-content-grid-template-columns-l, var(--pt-feed-content-grid-template-columns));
    --app-custom-grid-gap-row: var(--pt-feed-content-row-gap-l, var(--pt-feed-content-row-gap));
    --app-custom-grid-gap-column: var(--pt-feed-content-column-gap-l, var(--pt-feed-content-column-gap));
  }

  --app-custom-template-column-start: var(--pt-feed-content-grid-column-start, row-start);
  --app-custom-template-column-end: var(--pt-feed-content-grid-column-end, row-end);
  --app-custom-template-row-start: var(--pt-feed-content-grid-row-start);
  --app-custom-template-row-end: var(--pt-feed-content-grid-row-end);
}

.centered {
  display: flex;
  justify-content: center;
}

.emptyText {
  width: 100%;
  @include with-styled-text(pt-feed-empty-p);
  padding: var(--pt-feed-empty-p-padding);
  margin: var(--pt-feed-empty-p-margin);
  background: var(--pt-feed-empty-p-background);
  border: var(--pt-feed-empty-p-border);
  border-radius: var(--pt-feed-empty-p-border-radius);
  box-shadow: var(--pt-feed-empty-p-box-shadow);
}
