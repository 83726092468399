@import '../../resources/vars';
@import '../../resources/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: var(--input-group-align);
  row-gap: var(--coach-bars-row-gap, 16px);

  height: var(--coach-bars-height);
  width: var(--coach-bars-width);
  padding: var(--coach-bars-padding);
  margin: var(--coach-bars-margin);
  background: var(--coach-bars-background);
  border: var(--coach-bars-border);
  border-radius: var(--coach-bars-border-radius);
  box-shadow: var(--coach-bars-box-shadow);
}
