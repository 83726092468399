@import '../resources/vars';
@import '../resources/mixins';

.container {
  background: var(--activity-progress-background);
  padding: var(--activity-progress-padding);
  margin: var(--activity-progress-margin);
  border: var(--activity-progress-border);
  border-radius: var(--activity-progress-border-radius);
  box-shadow: var(--activity-progress-box-shadow);

  @include respond-to(sm, up) {
    padding: var(--activity-progress-padding-m);

  }

  @include respond-to(lg, up) {
    padding: var(--activity-progress-padding-l);

  }

  --app-custom-template-column-start: row-start;
  --app-custom-template-column-end: row-end;
}

.wrapper {
  display: flex;
  align-items: center;
  padding: var(--activity-progress-content-padding);
  margin: var(--activity-progress-content-margin);
  background: var(--activity-progress-content-background);
  border: var(--activity-progress-content-border);
  border-radius: var(--activity-progress-content-border-radius);
  box-shadow: var(--activity-progress-content-box-shadow);
  flex-direction: var(--activity-progress-content-flex-direction);
  gap: var(--activity-progress-content-gap);

  @include respond-to(sm, up) {
    flex-direction: var(--activity-progress-content-flex-direction-m);
    gap: var(--activity-progress-content-gap-m);
  }

  @include respond-to(lg, up) {
    flex-direction: var(--activity-progress-content-flex-direction-l);
    gap: var(--activity-progress-content-gap-l);
  }
}

.text{
  flex: 1 1;
  width: 100%
}

.progress {
  flex: 1 0;
  --progress-indicator-container-max-width: var(--activity-progress-items-max-width);

  --progress-indicator-wrapper-background: transparent;
  --progress-indicator-container-margin: var(--activity-progress-items-margin);
  --progress-indicator-container-gap: var(--activity-progress-items-gap);
  --progress-indicator-container-padding: var(--activity-progress-items-padding);

  --progress-indicator-background-current: var(--activity-progress-item-background-on);
  --progress-indicator-border-current: var(--activity-progress-item-border-on);
  --progress-indicator-box-shadow-current: var(--activity-progress-item-box-shadow-on);

  --progress-indicator-max-width: var(--activity-progress-item-max-width, 100%);

  --progress-indicator-border-radius: var(--activity-progress-item-border-radius);
  --progress-indicator-height: var(--activity-progress-item-height);

  --progress-indicator-background-after: var(--activity-progress-item-background-off);
  --progress-indicator-border-after: var(--activity-progress-item-border-off);
  --progress-indicator-box-shadow-after: var(--activity-progress-item-box-shadow-off);

  --progress-indicator-background-before: var(--activity-progress-item-background-on);
  --progress-indicator-border-before: var(--activity-progress-item-border-on);
  --progress-indicator-box-shadow-before: var(--activity-progress-item-box-shadow-on);
}

.message {
  p {
    @include with-styled-text(activity-progress-label);

    @include respond-to(sm, up) {
      font-size: var(--activity-progress-label-font-size-m);
    }

    @include respond-to(lg, up) {
      font-size: var(--activity-progress-label-font-size-l);
    }

    margin: var(--activity-progress-label-margin);
    padding: var(--activity-progress-label-padding);
  }
}
