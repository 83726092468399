@import '../resources/vars';
@import '../resources/mixins';

.header {
  --app-custom-template-column-start: var(--app-template-question-header-column-start);
  --app-custom-template-column-end: var(--app-template-question-header-column-end);
}

.grid {
  padding: var(--article-body-padding);

  @include respond-to(sm, up) {
    padding: var(--article-body-padding-m, var(--article-body-padding));
  }

  @include respond-to(lg, up) {
    padding: var(--article-body-padding-l, var(--article-body-padding));
  }
}

.reset-body {
  --article-body-padding: 0;
  --article-body-padding-m: 0;
  --article-body-padding-l: 0;
}

.item {
  --app-custom-template-column-start: row-start;
  --app-custom-template-column-end: row-end;
  display: flex;
  flex-direction: column;
  gap: var(--guidance-content-container-gap);
}

.centered {
  display: flex;
  justify-content: center;
}

.emptyText {
  width: 100%;
  @include with-styled-text(pt-feed-empty-p);
  padding: var(--pt-feed-empty-p-padding);
  margin: var(--pt-feed-empty-p-margin);
  background: var(--pt-feed-empty-p-background);
  border: var(--pt-feed-empty-p-border);
  border-radius: var(--pt-feed-empty-p-border-radius);
  box-shadow: var(--pt-feed-empty-p-box-shadow);
}

.score-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.action-status {
  display: flex;
  gap: 8px;

  flex-direction: var(--accordion-checkbox-container-direction);
  align-items: center;

  @include respond-to(sm, up) {
    flex-direction: var(--accordion-checkbox-container-direction-m);
  }

  @include respond-to(lg, up) {
    flex-direction: var(--accordion-checkbox-container-direction-l);
  }

}

.label {
  width: max-content;

  @include with-styled-text(accordion-checkbox-label);
  color: var(--accordion-checkbox-label-colour-off);
  margin: var(--accordion-checkbox-label-margin);
}

.checkbox {
  --app-custom-checkbox-on-icon-size: var(--accordion-checkbox-icon-size);
  --app-custom-checkbox-off-icon-size: var(--accordion-checkbox-icon-size);

  --app-custom-checkbox-on-bg-size: var(--accordion-checkbox-field-size);
  --app-custom-checkbox-off-bg-size: var(--accordion-checkbox-field-size);

  --app-custom-checkbox-on-bg: var(--accordion-checkbox-on-background-colour);
  --app-custom-checkbox-on-border: var(--accordion-checkbox-on-border);
  --app-custom-checkbox-on-icon-colour: var(--accordion-checkbox-on-icon-colour);
  --app-custom-checkbox-on-border-radius: var(--accordion-checkbox-on-border-radius);
  --app-custom-checkbox-on-box-shadow: var(--accordion-checkbox-on-box-shadow);


  --app-custom-checkbox-off-bg: var(--accordion-checkbox-off-background-colour);
  --app-custom-checkbox-off-border: var(--accordion-checkbox-off-border);
  --app-custom-checkbox-off-icon-colour: var(--accordion-checkbox-off-icon-colour);
  --app-custom-checkbox-off-border-radius: var(--accordion-checkbox-off-border-radius);
  --app-custom-checkbox-off-box-shadow: var(--accordion-checkbox-off-box-shadow);
}

.accordion {
  --app-custom-flex-grow: var(--accordion-action-grow);
  --app-custom-flex-basis: var(--accordion-action-basis);
  --app-custom-flex-shrink: var(--accordion-action-shrink);

  @include respond-to(sm, up) {
    --app-custom-flex-grow-m: var(--accordion-action-grow-m, var(--accordion-action-grow));
    --app-custom-flex-basis-m: var(--accordion-action-basis-m, var(--accordion-action-basis));
    --app-custom-flex-shrink-m: var(--accordion-action-shrink-m, var(--accordion-action-shrink));
  }

  @include respond-to(lg, up) {
    --app-custom-flex-grow-l: var(--accordion-action-grow-l, var(--accordion-action-grow));
    --app-custom-flex-basis-l: var(--accordion-action-basis-l, var(--accordion-action-basis));
    --app-custom-flex-shrink-l: var(--accordion-action-shrink-l, var(--accordion-action-shrink));
  }
}

.active-status {
  .label {
    color: var(--accordion-checkbox-label-colour-on);
  }
}

.action-select {
  display: flex;
  gap: 8px;
  width: 100%;

  flex-direction: var(--accordion-checkbox-container-direction);
  align-items: center;

  @include respond-to(sm, up) {
    flex-direction: var(--accordion-checkbox-container-direction-m);
  }

  @include respond-to(lg, up) {
    flex-direction: var(--accordion-checkbox-container-direction-l);
  }
}
