@import '../../resources/vars';
@import '../../resources/mixins';

.body {
  display: flex;
  flex-direction: column;
  flex: 1;
  --app-cusome-layout-sub-width: var(--template-video-max-width);
  background: var(--template-video-background);
  padding: var(--template-video-padding);

  @include respond-to(sm, up) {
    padding: var(--template-video-padding-m);
  }

  @include respond-to(lg, up) {
    padding: var(--template-video-padding-l);
  }
}

.video-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100dvh - var(--app-custom-top-nav-height) - var(--app-custom-bottom-nav-height));

  @include respond-to(lg, up) {
    height: calc(100dvh - var(--app-custom-top-nav-height));
  }
}

.video {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}

// TMP - remove when informer component is using the new css vars
.informers {
  display: flex;
  flex-direction: column;
  gap: var(--template-video-informers-gap);
  padding: var(--template-video-informers-padding);

  @include respond-to(sm, up) {
    gap: var(--template-video-informers-gap-m);
    padding: var(--template-video-informers-padding-m);
  }

  @include respond-to(lg, up) {
    gap: var(--template-video-informers-gap-l);
    padding: var(--template-video-informers-padding-l);
  }
}
