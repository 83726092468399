@import '../resources/vars';
@import '../resources/mixins';

.container {
  border-bottom: var(--theme-nav-top-keyline);
  box-shadow: var(--theme-nav-top-box-shadow);
  display: flex;
  height: var(--app-custom-top-nav-height);
}

.navigation {
  border-right: var(--theme-nav-top-keyline);
  width: var(--app-sidebar-width);
}

.body {
  display: flex;
  flex: 1;
  position: relative;

  &.offset {
    padding-right: var(--app-sidebar-width);
  }
}

.icons {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  padding: var(--nav-bar-web-icons-padding);
  gap: var(--nav-bar-web-icons-gap);

  @include respond-to(sm, up) {
    padding: var(--nav-bar-web-icons-padding-m);
    gap: var(--nav-bar-web-icons-gap-m);
  }

  @include respond-to(lg, up) {
    padding: var(--nav-bar-web-icons-padding-l);
    gap: var(--nav-bar-web-icons-gap-l);
  }

  &.left {
    left: 16px;
  }

  &.right {
    right: 16px;
  }
}

.mobile-nav {
  display: flex;
  align-items: center;
  gap: var(--nav-bar-web-icons-gap);
}

.slot {
  align-items: center;
  display: flex;
}

.slot,
.icon {
  background: var(--template-checkpoint-background);
  &:not(:last-child) {
    margin-right: 8px;
  }
}

.icon {
  &[aria-disabled="true"] {
    opacity: var(--pattern-disabled-opacity);
  }

  svg {
    height: var(--nav-top-icon-size);
    width: var(--nav-top-icon-size);
  }

  path {
    fill: var(--theme-nav-top-icon-off-colour);
  }
}

.wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  flex-shrink: 0;
}

.logo {
  img {
    height: var(--nav-top-logo-height);
    width: var(--nav-top-logo-width);
    --cover-img-fluid-width: var(--nav-top-logo-width);
  }
}

.profile {
  width: var(--nav-bar-web-profile-size);
  height: var(--nav-bar-web-profile-size);
  padding: var(--nav-bar-web-profile-padding);
  margin: var(--nav-bar-web-profile-margin);
  background: var(--nav-bar-web-profile-background);
  border: var(--nav-bar-web-profile-border);
  border-radius: var(--nav-bar-web-profile-border-radius);

  @include respond-to(sm, up) {
    padding: var(--nav-bar-web-profile-padding-m);
    margin: var(--nav-bar-web-profile-margin-m);
  }

  @include respond-to(lg, up) {
    padding: var(--nav-bar-web-profile-padding-l);
    margin: var(--nav-bar-web-profile-margin-l);
  }
}

.profile-text {
  @include with-styled-text(nav-bar-web-profile-text);

  @include respond-to(sm, up) {
    font-size: var(--nav-bar-web-profile-text-font-size-m);
  }

  @include respond-to(lg, up) {
    font-size: var(--nav-bar-web-profile-text-font-size-l);
  }
}
