@import '../resources/vars';
@import '../resources/mixins';

.container {
  display: block;

  &.cover {
    border-radius: var(--app-custom-img-border-radius, var(--cover-img-object-border-radius));
    object-fit: var(--cover-img-object-fit, cover);
    object-position: var(--cover-img-object-position);
  }

  &.fill {
    object-fit: fill;
  }
}

.fluid {
  height: 100%;
  object-fit: contain;
  //fix for safari issue with logo width
  width: var(--cover-img-fluid-width, 100%);
}

.blur {
  filter: blur(5px);
}
