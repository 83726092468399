@import '../resources/vars';
@import '../resources/mixins';

.container {
  --app-input-container-direction: var(--input-radio-container-direction);
  --app-input-container-align-items: var(--input-radio-container-align-items);
  --app-input-container-justify-content: var(--input-radio-container-justify-content);
  --app-input-container-padding: var(--input-radio-container-padding);
  --app-input-container-border-width: var(--input-radio-container-border-width);
  --app-input-container-border-style: var(--input-radio-container-border-style);
  --app-input-container-border-color: var(--input-radio-container-border-color);

  --app-input-label-direction: var(--input-radio-label-direction);
  --app-input-label-justify-content: var(--input-radio-label-justify-content);
  --app-input-label-align-items: var(--input-radio-label-align-items);
  --app-input-label-margin: var(--input-radio-label-margin);

  --app-input-informer-margin: var(--input-radio-informer-margin);


  // Explainer overwrites
  --app-custom-explainer-margin: var(--input-radio-option-explainer-margin);

  // set in the apps/firsthomecoach/src/styles/components/inputs/radio-group.module.scss
  --app-custom-explainer-label-colour: var(--app-custom-radio-option-explainer-color);

  --app-custom-explainer-label-font-family: var(--input-radio-option-explainer-font-family);
  --app-custom-explainer-label-font-weight: var(--input-radio-option-explainer-font-weight);
  --app-custom-explainer-label-font-style: var(--input-radio-option-explainer-font-style);
  --app-custom-explainer-label-line-height: var(--input-radio-option-explainer-line-height);
  --app-custom-explainer-label-letter-spacing: var(--input-radio-option-explainer-letter-spacing);
  --app-custom-explainer-label-text-transform: var(--input-radio-option-explainer-text-transform);
  --app-custom-explainer-label-text-align: var(--input-radio-option-explainer-text-align);

  // Labels container overwrites
  --app-custom-input-labels-container-display: flex;
  --app-custom-input-labels-container-direction: column;
  --app-custom-input-labels-container-gap: var(--input-radio-option-explainer-font-size);
  --app-custom-input-labels-container-gap-m: var(--input-radio-option-explainer-font-size-m);
  --app-custom-input-labels-container-gap-l: var(--input-radio-option-explainer-font-size-l);

  // Option image overwrites
  --app-custom-input-image-size: var(--input-radio-image-size);
  --app-custom-input-image-border-radius: var(--input-radio-image-border-radius);
  --app-custom-input-image-margin: var(--input-radio-image-margin);
  --app-custom-input-image-size-m: var(--input-radio-image-size-m);
  --app-custom-input-image-margin-m: var(--input-radio-image-margin-m);
  --app-custom-input-image-size-l: var(--input-radio-image-size-l);
  --app-custom-input-image-margin-l: var(--input-radio-image-margin-l);

  // Labels container overwrites
  --app-custom-input-label-container-gap: var(--input-radio-labels-container-gap);
  --app-custom-input-label-container-gap-m: var(--input-radio-labels-container-gap-m);
  --app-custom-input-label-container-gap-l: var(--input-radio-labels-container-gap-l);


  // Sub-label overwrites
  @include rewrite-styled-text-vars(app-custom-input-sub-label, input-radio-sublabel);
}

.field {
  @at-root .input:checked + .icon {
    background-color: var(--input-radio-on-background-colour);
    border: var(--input-radio-on-border);
    border-radius: var(--input-radio-on-border-radius);
    box-shadow: var(--input-radio-on-box-shadow);
    height: var(--input-radio-on-background-size);
    width: var(--input-radio-on-background-size);

    svg {
      height: var(--input-radio-on-icon-size);
      width: var(--input-radio-on-icon-size);
    }

    path {
      fill: var(--input-radio-on-icon-colour);
    }

    circle {
      fill: var(--input-radio-on-icon-colour);
    }
  }

  @include outline-control('.input:focus + .icon');
}

.input {
  @extend %checkbox-reset;
}

.icon {
  background-color: var(--input-radio-off-background-colour);
  border: var(--input-radio-off-border);
  border-radius: var(--input-radio-off-border-radius);
  box-shadow: var(--input-radio-off-box-shadow);
  display: block;
  height: var(--input-radio-off-background-size);
  width: var(--input-radio-off-background-size);

  svg {
    height: var(--input-radio-off-icon-size);
    width: var(--input-radio-off-icon-size);
  }

  path {
    fill: var(--input-radio-off-icon-colour);
  }

  circle {
    fill: var(--input-radio-off-icon-colour);
  }

  &.pulse {
    animation: pulse 2s infinite;
  }
}

.icon-disabled {
  border: var(--input-radio-off-border-disabled);
}

.label {
  @include with-styled-text(input-radio-label);
  color: var(--app-custom-input-label-color);
}

.option-2 {
  @include with-styled-text(input-radio-label-opt2);
  color: var(--app-custom-input-label-opt2-color);
}
