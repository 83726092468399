@import '../resources/vars';
@import '../resources/mixins';

.container {
  @include respond-to(lg, up) {
    --app-custom-template-column-start: var(--app-template-articles-body-column-start);
    --app-custom-template-column-end: var(--app-template-articles-body-column-end);
  }
}

.body {
  @include respond-to(lg, up) {
    --app-custom-template-column-start: var(--app-template-article-body-column-start);
    --app-custom-template-column-end: var(--app-template-article-body-column-end);
  }
}

.section {
  background-color: var(--knowledge-section-container-bg-colour);
  border: var(--knowledge-section-container-border);
  border-radius: var(--knowledge-section-container-border-radius);
  box-shadow: var(--knowledge-section-container-box-shadow);
  padding: var(--knowledge-section-container-padding);
  margin: var(--knowledge-section-container-margin);

  &:nth-child(even) {
    background-color: var(--knowledge-section-container-even-bg-colour);
  }
}

.meta {
  align-items: var(--template-articles-metabox-align);
  display: var(--template-articles-metabox-display);
  flex-direction: var(--template-articles-metabox-direction);
  justify-content: var(--template-articles-metabox-justify);
  padding: var(--template-articles-metabox-padding);
}

.meta {
  background-color: var(--template-articles-metabox-bg-colour);
  border: var(--template-articles-metabox-border);
  border-radius: var(--template-articles-metabox-border-radius);
  box-shadow: var(--template-articles-metabox-box-shadow);
  color: var(--template-articles-metabox-colour);
  font-family: var(--template-articles-metabox-font-family);
  font-size: var(--template-articles-metabox-font-size);
  font-style: var(--template-articles-metabox-font-style);
  font-weight: var(--template-articles-metabox-font-weight);
  letter-spacing: var(--template-articles-metabox-letter-spacing);
  line-height: var(--template-articles-metabox-line-height);
  text-transform: var(--template-articles-metabox-text-transform);
}

.meta-list {
  display: var(--template-articles-metabox-list-display);
  flex-direction: var(--template-articles-metabox-list-direction);
  margin: var(--template-articles-metabox-list-margin);
  padding: var(--template-articles-metabox-list-padding);
}

.meta-item:not(:first-child) {
  border-color: var(--template-articles-metabox-item-border-colour);
  border-style: solid;
  border-width: var(--template-articles-metabox-item-border);
  margin: var(--template-articles-metabox-item-margin);
  padding: var(--template-articles-metabox-item-padding);
}

.intro {
  color: var(--template-articles-intro-colour);
  font-family: var(--template-articles-intro-font-face);
  font-size: var(--template-articles-intro-font-size);
  font-style: var(--template-articles-intro-font-style);
  font-weight: var(--template-articles-intro-font-weight);
  letter-spacing: var(--template-articles-intro-letter-spacing);
  line-height: var(--template-articles-intro-line-height);
  text-align: var(--template-articles-intro-text-align);
  text-transform: var(--template-articles-intro-text-transform);
}

.footer-legal {
  // page legal container
  --app-custom-footer-text-container-margin: var(--footer-legal-margin);
  --app-custom-footer-text-container-padding: var(--footer-legal-padding);
  --app-custom-footer-text-container-background: var(--footer-legal-background);

  @include respond-to(md, up) {
    --app-custom-footer-text-container-margin: var(--footer-legal-margin-m, var(--footer-legal-margin));
    --app-custom-footer-text-container-padding: var(--footer-legal-padding-m, var(--footer-legal-padding));
  }

  @include respond-to(lg, up) {
    --app-custom-footer-text-container-margin: var(--footer-legal-margin-l, var(--footer-legal-margin));
    --app-custom-footer-text-container-padding: var(--footer-legal-padding-l, var(--footer-legal-padding));
  }

  // page legal text
  --app-custom-footer-text-margin: var(--footer-legal-text-margin);
  --app-custom-footer-text-padding: var(--footer-legal-text-padding);

  --app-custom-footer-text-colour: var(--footer-legal-text-colour);
  --app-custom-footer-text-font-face: var(--footer-legal-text-font-face);
  --app-custom-footer-text-font-size: var(--footer-legal-text-font-size);
  --app-custom-footer-text-font-weight: var(--footer-legal-text-font-weight);
  --app-custom-footer-text-font-style: var(--footer-legal-text-font-style);
  --app-custom-footer-text-line-height: var(--footer-legal-text-line-height);
  --app-custom-footer-text-letter-spacing: var(--footer-legal-text-letter-spacing);
  --app-custom-footer-text-text-transform: var(--footer-legal-text-text-transform);
  --app-custom-footer-text-align: var(--footer-legal-text-text-align);

  @include respond-to(md, up) {
    --app-custom-footer-text-font-size: var(--footer-legal-text-font-size-m, var(--footer-legal-text-font-size));
    --app-custom-footer-text-margin: var(--footer-legal-text-margin-m, var(--footer-legal-text-margin));
    --app-custom-footer-text-padding: var(--footer-legal-text-padding-m, var(--footer-legal-text-padding));
  }

  @include respond-to(lg, up) {
    --app-custom-footer-text-font-size: var(--footer-legal-text-font-size-l, var(--footer-legal-text-font-size));
    --app-custom-footer-text-margin: var(--footer-legal-text-margin-l, var(--footer-legal-text-margin));
    --app-custom-footer-text-padding: var(--footer-legal-text-padding-l, var(--footer-legal-text-padding));
  }
}

.image {
  height: var(--knowledge-img-object-height);
  padding: var(--knowledge-img-object-padding);
  --cover-img-object-border-radius: var(--knowledge-img-object-border-radius);
  --cover-img-object-fit: var(--knowledge-img-object-fit);
  --cover-img-object-position: var(--knowledge-img-object-position);

  @include respond-to(md, up) {
    height: var(--knowledge-img-object-height-m);
    padding: var(--knowledge-img-object-padding-m);
  }

  @include respond-to(lg, up) {
    height: var(--knowledge-img-object-height-l);
    padding: var(--knowledge-img-object-padding-l);
  }
}
