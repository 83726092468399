@import '../../resources/vars';
@import '../../resources/mixins';

.container {
  width: 100%;
  --app-custom-grid-width: 100%;

  --app-custom-template-column-start: edge-start;
  --app-custom-template-column-end: edge-end;

  @include respond-to(sm, up) {
    --app-custom-template-column-start: row-start;
    --app-custom-template-column-end: row-end;
  }
}

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  --app-custom-template-column-start: edge-start;
  --app-custom-template-column-end: edge-end;

  @include respond-to(sm, up) {
    flex-direction: row;
  }
}

.category-body {
  display: none;

  @include respond-to(sm, up) {
    display: block;
  }
}

.category-sub-block {
  position: absolute;
  bottom: 0;
  left: 0;

  @include respond-to(sm, up) {
    display: none;
  }
}

.meta {
  display: var(--template-articles-metabox-list-display);
  flex-direction: var(--template-articles-metabox-list-direction);
  margin: var(--template-articles-metabox-list-margin);
  padding: var(--template-articles-metabox-list-padding);
}

.meta-item {
  @include with-styled-text(page-header-vm-bf-meta-label);
}

.meta-item:not(:first-child) {
  border-color: var(--template-articles-metabox-item-border-colour);
  border-style: solid;
  border-width: var(--template-articles-metabox-item-border);
  margin: var(--template-articles-metabox-item-margin);
  padding: var(--template-articles-metabox-item-padding);
}

.block-image {
  height: 100%;
  width: 100%;
  position: unset;

  @include respond-to(sm, up) {
    position: absolute;
  }
}

.image {
  flex: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.content{
  background: var(--page-header-content-background);
}
