@use '../resources.scss' as mixins;

.heading-page-title {
  @include mixins.text-zone;
  font-family: var(--face-heading);
  font-size: var(--heading-page-title-size);
  font-weight: var(--heading-page-title-weight);
  letter-spacing: var(--letter-spacing-heading);
  line-height: var(--line-height-heading);
}

.heading-paragraph-m {
  @include mixins.text-zone;
  font-family: var(--face-heading);
  font-size: var(--heading-paragraph-m-size);
  font-weight: var(--heading-paragraph-m-weight);
  letter-spacing: var(--letter-spacing-heading);
  line-height: var(--line-height-heading);
}

.heading-paragraph-s {
  @include mixins.text-zone;
  font-family: var(--face-heading);
  font-size: var(--heading-paragraph-s-size);
  font-weight: var(--heading-paragraph-s-weight);
  letter-spacing: var(--letter-spacing-heading);
  line-height: var(--line-height-heading);
}

.body-paragraph-m {
  @include mixins.text-zone;
  font-family: var(--face-body);
  font-size: var(--body-paragraph-m-size);
  font-weight: var(--body-paragraph-m-weight);
  letter-spacing: var(--letter-spacing-body);
  line-height: var(--line-height-body);
}

.body-paragraph-s {
  @include mixins.text-zone;
  font-family: var(--face-body);
  font-size: var(--body-paragraph-s-size);
  font-weight: var(--body-paragraph-s-weight);
  letter-spacing: var(--letter-spacing-body);
  line-height: var(--line-height-body);
}

.body-paragraph-xs {
  @include mixins.text-zone;
  font-family: var(--face-body);
  font-size: var(--body-paragraph-xs-size);
  font-weight: var(--body-paragraph-xs-weight);
  letter-spacing: var(--letter-spacing-body);
  line-height: var(--line-height-body);
}

.output-s {
  @include mixins.text-zone;
  font-family: var(--face-data-output);
  font-size: var(--data-output-s-size);
  font-weight: var(--data-output-s-weight);
  letter-spacing: var(--letter-spacing-data-output);
  line-height: var(--line-height-data-output);
}

.output-m {
  @include mixins.text-zone;
  font-family: var(--face-data-output);
  font-size: var(--data-output-m-size);
  font-weight: var(--data-output-m-weight);
  letter-spacing: var(--letter-spacing-data-output);
  line-height: var(--line-height-data-output);
}

.output-l {
  @include mixins.text-zone;
  font-family: var(--face-data-output);
  font-size: var(--data-output-l-size);
  font-weight: var(--data-output-l-weight);
  letter-spacing: var(--letter-spacing-data-output);
  line-height: var(--line-height-data-output);
}

.label-s {
  @include mixins.text-zone;
  font-family: var(--face-data-label);
  font-size: var(--data-label-s-size);
  font-weight: var(--data-label-s-weight);
  letter-spacing: var(--letter-spacing-data-label);
  line-height: var(--line-height-data-label);
}

.label-m {
  @include mixins.text-zone;
  font-family: var(--face-data-label);
  font-size: var(--data-label-m-size);
  font-weight: var(--data-label-m-weight);
  letter-spacing: var(--letter-spacing-data-label);
  line-height: var(--line-height-data-label);
}

.input-value {
  @include mixins.text-zone;
  font-family: var(--face-forms);
  font-size: var(--forms-input-value-size);
  font-weight: var(--forms-input-value-weight);
  letter-spacing: var(--letter-spacing-data-label);
  line-height: var(--line-height-data-label);
}

.input-label {
  @include mixins.text-zone;
  font-family: var(--face-forms);
  font-size: var(--forms-input-label-size);
  font-weight: var(--forms-input-label-weight);
  letter-spacing: var(--letter-spacing-data-label);
  line-height: var(--line-height-data-label);
}

.wheel-value {
  @include mixins.text-zone;
  font-family: var(--face-forms);
  font-size: var(--forms-wheel-value-size);
  font-weight: var(--forms-wheel-value-weight);
  letter-spacing: var(--letter-spacing-data-output);
  line-height: var(--line-height-data-output);
}

.wheel-label {
  @include mixins.text-zone;
  font-family: var(--face-forms);
  font-size: var(--forms-wheel-label-size);
  font-weight: var(--forms-wheel-label-weight);
  letter-spacing: var(--letter-spacing-data-label);
  line-height: var(--line-height-data-label);
}

.navigation-text {
  color: var(--swatch-nav-bar-text);
  font-family: var(--face-navigation);
  font-size: var(--nav-bar-text-size);
  font-weight: var(--nav-bar-text-weight);
  letter-spacing: var(--letter-spacing-data-label);
  line-height: var(--line-height-data-label);
}

.sub-topic {
  margin-block-end: 0;
  margin-block-start: 0;
}

.field-label {
  @include mixins.text-zone;
  font-family: var(--face-forms);
  font-size: var(--forms-field-label-size);
  font-weight: var(--forms-field-label-weight);
  letter-spacing: var(--letter-spacing-data-label);
  line-height: var(--line-height-data-label);
}

.forms-option {
  @include mixins.text-zone;
  font-family: var(--face-forms);
  font-size: var(--forms-field-option-size);
  font-weight: var(--forms-field-option-weight);
  letter-spacing: var(--letter-spacing-data-label);
  line-height: var(--line-height-data-label);
}

.cover-title {
  font-family: var(--cover-heading-face);
  font-size: var(--cover-heading-size-s);
  font-style: var(--cover-heading-font-style);
  font-weight: var(--cover-heading-weight);
  letter-spacing: var(--cover-heading-letter-spacing);
  line-height: var(--cover-heading-line-height);

  @include mixins.respond-to(sm, up) {
    font-size: var(--cover-heading-size-ml);
  }
}

.cover-subtitle {
  font-family: var(--cover-sub-heading-face);
  font-size: var(--cover-sub-heading-size-s);
  font-style: var(--cover-sub-heading-font-style);
  font-weight: var(--cover-sub-heading-weight);
  letter-spacing: var(--cover-sub-heading-letter-spacing);
  line-height: var(--cover-sub-heading-line-height);

  @include mixins.respond-to(sm, up) {
    font-size: var(--cover-sub-heading-size-ml);
  }
}
