@import '../../../../apps/firsthomecoach/src/styles/resources/vars';
@import '../../../../apps/firsthomecoach/src/styles/resources/mixins';

.container {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: var(--app-z-index-layer-1500);
  justify-content: var(--app-dimmer-align, flex-start);
}

.soft {
  pointer-events: none;
  visibility: hidden;

  &[data-visible='true'] {
    pointer-events: all;
    visibility: visible;
  }
}
