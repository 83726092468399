@import '../resources/vars';
@import '../resources/mixins';

.container {
  align-items: center;
  display: flex;
  justify-content: center;

  &.inline {
    display: inline-flex;
  }

  &.hidden {
    display: none;
  }

  svg {
    display: block;
  }
}
