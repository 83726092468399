@import '../resources/vars';
@import '../resources/mixins';

.container {
  background-color: var(--modal-header-background-color);
  border: var(--modal-header-keyline-bottom);
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: var(--modal-header-padding);
  gap: var(--modal-header-gap);

  @include respond-to(sm, up) {
    padding: var(--modal-header-padding-m, var(--modal-header-padding));
    gap: var(--modal-header-gap-m, var(--modal-header-gap));
  }

  @include respond-to(lg, up) {
    padding: var(--modal-header-padding-l, var(--modal-header-padding));
    gap: var(--modal-header-gap-l, var(--modal-header-gap));
  }
}

.label {
  align-items: center;
  display: flex;
}

.title {
  flex: 1 0;
}

.title {
  @include with-styled-text(modal-header-heading);

  @include respond-to(sm, up) {
    font-size: var(--modal-header-heading-font-size-m, var(--modal-header-heading-font-size));
  }

  @include respond-to(lg, up) {
    font-size: var(--modal-header-heading-font-size-l, var(--modal-header-heading-font-size));
  }
}

.subtitle {
  @include with-styled-text(modal-header-sub-heading);

  @include respond-to(sm, up) {
    font-size: var(--modal-header-sub-heading-font-size-m, var(--modal-header-sub-heading-font-size));
  }

  @include respond-to(lg, up) {
    font-size: var(--modal-header-sub-heading-font-size-l, var(--modal-header-sub-heading-font-size));
  }
}

.icon {
  background-color: var(--modal-header-icon-bg-color);
  border: var(--modal-header-icon-bg-border);
  border-radius: var(--modal-header-icon-bg-border-radius);
  flex-shrink: 0;
  margin: var(--modal-header-icon-bg-margin);
  padding: var(--modal-header-icon-bg-padding);

  &:only-child {
    margin-left: auto;
  }

  svg {
    height: var(--modal-header-icon-size);
    width: var(--modal-header-icon-size);

    path {
      fill: var(--modal-header-icon-color);
    }
  }
}

.left-image {
  background-color: var(--modal-header-left-icon-bg-color);
  border: var(--modal-header-left-icon-bg-border);
  border-radius: var(--modal-header-left-icon-bg-border);
  flex-shrink: 0;
  padding: var(--modal-header-left-icon-bg-padding);
  margin: var(--modal-header-left-icon-bg-margin);

  height: var(--modal-header-left-icon-size);
  width: var(--modal-header-left-icon-size);
}

.left-icon {
  background-color: var(--modal-header-left-icon-bg-color);
  border: var(--modal-header-left-icon-bg-border);
  border-radius: var(--modal-header-left-icon-bg-border);
  flex-shrink: 0;
  padding: var(--modal-header-left-icon-bg-padding);
  margin: var(--modal-header-left-icon-bg-margin);

  &:only-child {
    margin-right: auto;
  }

  svg {
    height: var(--modal-header-left-icon-size);
    width: var(--modal-header-left-icon-size);

    path {
      fill: var(--modal-header-left-icon-color);
    }
  }
}
