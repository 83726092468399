@import '../resources/vars';
@import '../resources/mixins';

.container {
  position: relative;
}

.ring {
  border-radius: 50%;
  display: block;
  transform: rotate(-90deg);
  width: 100%;
}

.circle {
  fill: var(--app-pie-chart-whole-fill, var(--pie-chart-whole-fill));
  stroke: var(--app-pie-chart-whole-stroke, var(--pie-chart-whole-stroke));
  stroke-dasharray: 100 100;
}

.progress {
  fill: var(--app-pie-chart-value-fill, var(--pie-chart-value-fill));
  stroke: var(--app-pie-chart-outer-value-stroke, var(--pie-chart-value-stroke));
  stroke-linecap: var(--app-pie-chart-value-stroke-linecap, var(--pie-chart-value-stroke-linecap));
  transition: stroke-dasharray 0.25s ease-out;
}

// TODO: change variables after Olly's updates
.lower-progress {
  fill: var(--app-pie-chart-value-fill, var(--pie-chart-value-fill));
  stroke: var(--app-pie-chart-inner-value-stroke, var(--pie-chart-value-stroke));
  stroke-linecap: var(--app-pie-chart-value-stroke-linecap, var(--pie-chart-value-stroke-linecap));
  transition: stroke-dasharray 0.25s ease-out;
}

.content {
  display: flex;
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.children {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  gap: var(--app-custom-pie-chart-children-gap);
  padding: var(--app-custom-pie-chart-children-padding)
}

.marker {
  transform-origin: 50% 50%;
  fill: var(--app-pie-chart-marker-fill, var(--pie-chart-marker-fill));
  transition: transform 0.25s ease-out 0.25s;
  filter: drop-shadow( 3px 0px 3px grey);
}

// TODO: change variables after Olly's updates
.lower-marker {
  transform-origin: 50% 50%;
  fill: var(--app-pie-chart-marker-fill, var(--pie-chart-marker-fill));
  transition: transform 0.25s ease-out 0.25s;
  filter: drop-shadow( 3px 0px 3px grey);
}
