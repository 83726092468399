@import '../resources/vars';
@import '../resources/mixins';

@include respond-to(lg, up) {
  .header {
    --app-custom-template-column-start: var(--app-template-checklist-header-column-start);
    --app-custom-template-column-end: var(--app-template-checklist-header-column-end);
  }

  .body {
    --app-custom-template-column-start: var(--app-template-checklist-body-column-start);
    --app-custom-template-column-end: var(--app-template-checklist-body-column-end);
  }
}

.wrapper {
  display:flex;
  flex-direction: column;
  background: var(--checklist-sections-container-bg-colour);
  border: var(--checklist-sections-container-border);
  border-radius: var(--checklist-sections-container-border-radius);
  padding: var(--checklist-sections-container-padding);
  margin: var(--checklist-sections-container-margin);
  gap: var(--checklist-sections-container-gap);

  @include respond-to(sm, up){
    padding: var(--checklist-sections-container-padding-m);
  }

  @include respond-to(lg, up){
    padding: var(--checklist-sections-container-padding-l);
  }
}
