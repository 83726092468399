@import '../resources/vars';
@import '../resources/mixins';

.container {
  align-items: flex-end;
  background-color: var( --modal-dimmer-color);

  @include respond-to(sm, up) {
    align-items: center;
    --app-dimmer-align: center;
  }
}

.safe {
  display: flex;
  max-height: 100dvh;
  width: 100%;
  justify-content: center;
}

.dialog {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  border: var(--modal-border);
  border-radius: var(--modal-border-radius);
  flex-basis: 100%;
  -webkit-font-smoothing: antialiased;
  transform: translatez(0);
  background-color: var(--modal-content-bg);
  max-height: var(--app-cutom-modal-max-height, 100%);
  box-shadow: var(--modal-box-shadow);
  overflow: hidden;
  transition: max-height ease-in-out 200ms;

  @include respond-to(sm, up) {
    border: var(--modal-border-m, var(--modal-border));
    border-radius: var(--modal-border-radius-m, var(--modal-border-radius));
    flex-basis: var(--modal-width-m, 50%);
  }

  @include respond-to(lg, up) {
    border: var(--modal-border-l, var(--modal-border));
    border-radius: var(--modal-border-radius-l, var(--modal-border-radius));
    flex-basis: var(--modal-width-l, 50%);
  }
}

.body {
  padding: var(--modal-body-padding);

  @include respond-to(sm, up) {
    padding: var(--modal-body-padding-m, var(--modal-body-padding));
  }

  @include respond-to(lg, up) {
    padding: var(--modal-body-padding-l, var(--modal-body-padding));
  }

}

.loader {
  height: 128px;
  width: 128px;
}

.appear,
.enter {
  transform: translateY(100%);
  will-change: transform;
}

.appearActive,
.enterActive {
  transform: translateY(0);
  transition: transform ease-in-out 200ms;
}

.modal-footer {
  // modal footer container
  --app-custom-footer-text-container-margin: var(--modal-footer-margin);
  --app-custom-footer-text-container-padding: var(--modal-footer-padding);
  --app-custom-footer-text-container-background: var(--modal-footer-background);

  @include respond-to(md, up) {
    --app-custom-footer-text-container-margin: var(--modal-footer-margin-m, var(--modal-footer-margin));
    --app-custom-footer-text-container-padding: var(--modal-footer-padding-m, var(--modal-footer-padding));
  }

  @include respond-to(lg, up) {
    --app-custom-footer-text-container-margin: var(--modal-footer-margin-l, var(--modal-footer-margin));
    --app-custom-footer-text-container-padding: var(--modal-footer-padding-l, var(--modal-footer-padding));
  }

  // modal footer text
  --app-custom-footer-text-margin: var(--modal-footer-text-margin);
  --app-custom-footer-text-padding: var(--modal-footer-text-padding);

  --app-custom-footer-text-colour: var(--modal-footer-text-colour);
  --app-custom-footer-text-font-face: var(--modal-footer-text-font-face);
  --app-custom-footer-text-font-size: var(--modal-footer-text-font-size);
  --app-custom-footer-text-font-weight: var(--modal-footer-text-font-weight);
  --app-custom-footer-text-font-style: var(--modal-footer-text-font-style);
  --app-custom-footer-text-line-height: var(--modal-footer-text-line-height);
  --app-custom-footer-text-letter-spacing: var(--modal-footer-text-letter-spacing);
  --app-custom-footer-text-text-transform: var(--modal-footer-text-text-transform);
  --app-custom-footer-text-align: var(--modal-footer-text-text-align);

  @include respond-to(md, up) {
    --app-custom-footer-text-font-size: var(--modal-footer-text-font-size-m, var(--modal-footer-text-font-size));
    --app-custom-footer-text-margin: var(--modal-footer-text-margin-m, var(--modal-footer-text-margin));
    --app-custom-footer-text-padding: var(--modal-footer-text-padding-m, var(--modal-footer-text-padding));
  }

  @include respond-to(lg, up) {
    --app-custom-footer-text-font-size: var(--modal-footer-text-font-size-l, var(--modal-footer-text-font-size));
    --app-custom-footer-text-margin: var(--modal-footer-text-margin-l, var(--modal-footer-text-margin));
    --app-custom-footer-text-padding: var(--modal-footer-text-padding-l, var(--modal-footer-text-padding));
  }
}
