@import '../resources/vars';
@import '../resources/mixins';

.wrapper {
  background: var(--progress-indicator-wrapper-background, var(--theme-template-bg-colour));
  padding: var(--progress-indicator-container-padding);
  @include respond-to(sm, up) {
    padding: var(--progress-indicator-container-padding);
  }
  @include respond-to(lg, up) {
    padding: var(--progress-indicator-container-padding);
  }
}

.indicator-parent-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: var(--progress-indicator-parent-container-gap);
  @include respond-to(sm, up) {
    gap: var(--progress-indicator-parent-container-gap-m);

  }
  @include respond-to(lg, up) {
    gap: var(--progress-indicator-parent-container-gap-l);
  }
}

.indicator {
  @include with-styled-text(progress-indicator-label);
}

.container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: var(--progress-indicator-container-justify-content, space-between);
  max-width: var(--progress-indicator-container-max-width, 400px);
  margin: var(--progress-indicator-container-margin);
  background: var(--progress-indicator-container-background);
  gap: var(--progress-indicator-container-gap);
  border-radius: var(--progress-indicator-container-border-radius);
  overflow: hidden;

  @include respond-to(sm, up) {
    gap: var(--progress-indicator-container-gap-m, var(--progress-indicator-container-gap));
    border-radius: var(--progress-indicator-container-border-radius-m, var(--progress-indicator-container-border-radius));
  }

  @include respond-to(lg, up) {
    gap: var(--progress-indicator-container-gap-l, var(--progress-indicator-container-gap));
    border-radius: var(--progress-indicator-container-border-radius-l, var(--progress-indicator-container-border-radius));
  }
}

.key-line {
  position: absolute;
  z-index: var(--app-z-index-layer-50);
  border-radius: var(--progress-indicator-keyline-border-radius);
  background: var(--progress-indicator-keyline-background);
  height: var(--progress-indicator-keyline-height);
  top: var(--progress-indicator-keyline-top);
  left: var(--progress-indicator-keyline-left);
  right: var(--progress-indicator-keyline-right);

  @include respond-to(sm, up) {
    background: var(--progress-indicator-keyline-background-m, var(--progress-indicator-keyline-background));
    height: var(--progress-indicator-keyline-height-m, var(--progress-indicator-keyline-height));
    top: var(--progress-indicator-keyline-top-m, var(--progress-indicator-keyline-top));
    left: var(--progress-indicator-keyline-left-m, var(--progress-indicator-keyline-left));
    right: var(--progress-indicator-keyline-right-m, var(--progress-indicator-keyline-right));
  }

  @include respond-to(lg, up) {
    background: var(--progress-indicator-keyline-background-l, var(--progress-indicator-keyline-background));
    height: var(--progress-indicator-keyline-height-l, var(--progress-indicator-keyline-height));
    top: var(--progress-indicator-keyline-top-l, var(--progress-indicator-keyline-top));
    left: var(--progress-indicator-keyline-left-l, var(--progress-indicator-keyline-left));
    right: var(--progress-indicator-keyline-right-l, var(--progress-indicator-keyline-right));
  }
}

.step {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  z-index: var(--app-z-index-layer-100);
  height: var(--progress-indicator-height);
  width: var(--progress-indicator-width, 100%);
  max-width: var(--progress-indicator-max-width, 100px);
  padding: var(--progress-indicator-padding);
  border-radius: var(--progress-indicator-border-radius);

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  @include with-styled-text(progress-indicator-text);

  @include respond-to(sm, up) {
    height: var(--progress-indicator-height-m, var(--progress-indicator-height));
    width: var(--progress-indicator-width-m, var(--progress-indicator-width, 100%));
    max-width: var(--progress-indicator-max-width-m, var(--progress-indicator-max-width, 100px));
    padding: var(--progress-indicator-padding-m, var(--progress-indicator-padding));
    border-radius: var(--progress-indicator-border-radius-m, var(--progress-indicator-border-radius));

    font-size: var(progress-indicator-text-font-size-m, var(progress-indicator-text-font-size));
  }

  @include respond-to(lg, up) {
    height: var(--progress-indicator-height-l, var(--progress-indicator-height));
    width: var(--progress-indicator-width-l, var(--progress-indicator-width, 100%));
    max-width: var(--progress-indicator-max-width-l, var(--progress-indicator-max-width, 100px));
    padding: var(--progress-indicator-padding-l, var(--progress-indicator-padding));
    border-radius: var(--progress-indicator-border-radius-l, var(--progress-indicator-border-radius));

    font-size: var(progress-indicator-text-font-size-l, var(progress-indicator-text-font-size));
  }
}

.icon {
  margin: var(--progress-indicator-icon-margin);

  svg {
    height: var(--progress-indicator-icon-size);
    width: var(--progress-indicator-icon-size);

    @include respond-to(sm, up) {
      height: var(--progress-indicator-icon-size-m, var(--progress-indicator-icon-size));
      width: var(--progress-indicator-icon-size-m, var(--progress-indicator-icon-size));
    }

    @include respond-to(lg, up) {
      height: var(--progress-indicator-icon-size-l, var(--progress-indicator-icon-size));
      width: var(--progress-indicator-icon-size-l, var(--progress-indicator-icon-size));
    }
  }
}

.before {
  background: var(--progress-indicator-background-before);
  border: var(--progress-indicator-border-before);
  color: var(--progress-indicator-text-color-before);
  box-shadow: var(--progress-indicator-box-shadow-before);
  border-radius: var(--progress-indicator-border-radius-before);

  path {
    fill: var(--progress-indicator-icon-color-before);
  }
}

.current {
  background: var(--progress-indicator-background-current);
  border: var(--progress-indicator-border-current);
  color: var(--progress-indicator-text-color-current);
  box-shadow: var(--progress-indicator-box-shadow-current);
  border-radius: var(--progress-indicator-border-radius-current);

  path {
    fill: var(--progress-indicator-icon-color-current);
  }
}

.after {
  background: var(--progress-indicator-background-after);
  border: var(--progress-indicator-border-after);
  color: var(--progress-indicator-text-color-after);
  box-shadow: var(--progress-indicator-box-shadow-after);
  border-radius: var(--progress-indicator-border-radius-after);

  path {
    fill: var(--progress-indicator-icon-color-after);
  }
}
