@import '../resources/vars';
@import '../resources/mixins';

.grid {
  display: grid;
  grid-gap: var(--share-btn-group-gutter);
  grid-template-columns: repeat(var(--share-btn-group-columns), 1fr);
}

.heading {
  @include with-styled-text(share-heading);
}

.social-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.social {
  align-items: center;
  background-color: var(--share-network-button-background-color);
  border: var(--share-network-button-border);
  border-radius: var(--share-network-button-border-radius);
  box-shadow: var(--share-network-button-box-shadow);
  color: inherit;
  display: flex;
  flex-direction: var(--share-network-button-flex-direction);
  justify-content: var(--share-network-button-justify-content);
  margin: var(--share-network-button-margin);
  min-height: 48px; // provide
  padding: var(--share-network-button-padding);
  text-decoration: none;
  width: var(--share-network-button-width);

  span {
    @include with-styled-text(share-network-button-label);
    margin: var(--share-network-button-label-margin);
  }

  svg {
    height: var(--share-network-button-icon-size);
    width: var(--share-network-button-icon-size);
  }
}

.clipboard {
  align-items: center;
  background-color: var(--share-url-background-colour);
  border: var(--share-url-border);
  border-radius: var(--share-url-border-radius);
  box-shadow: var(--share-url-box-shadow);
  display: flex;
  flex-direction: var(--share-url-flex-direction);
  justify-content: space-between;
  padding: var(--share-url-padding);
  width: 100%;
}

.copy {
  align-items: center;
  background-color: var(--share-url-button-off-background-color);
  border: var(--share-url-button-off-border);
  border-radius: var(--share-url-button-off-border-radius);
  box-shadow: var(--share-url-button-off-box-shadow);
  display: flex;
  justify-content: center;
  margin: var(--share-url-button-off-margin);
  min-height: 32px; //provide
  padding: var(--share-url-button-off-padding);

  width: var(--share-url-button-off-width);

  span {
    @include with-styled-text(share-url-button-off-label);
  }
}

.copied {
  background-color: var(--share-url-button-on-background-color);
  border: var(--share-url-button-on-border);
  border-radius: var(--share-url-button-on-border-radius);
  box-shadow: var(--share-url-button-on-box-shadow);
  margin: var(--share-url-button-on-margin);
  padding: var(--share-url-button-on-padding);
  width: var(--share-url-button-on-width);

  span {
    @include with-styled-text(share-url-button-on-label);
  }
}

.link {
  @include with-styled-text(share-url-text);
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
