@import '../resources/vars';
@import '../resources/mixins';

.body {
  flex: 1;

  --app-custom-template-column-start: edge-start;
  --app-custom-template-column-end: edge-end;
  --app-grid-gap-column: 0px;
  --app-grid-rows: auto;

  @include respond-to(lg, up) {
    --app-custom-template-column-start: row-start;
    --app-custom-template-column-end: row-end;
  }

  // stepper buttons
  --app-card-slider-button-background: var(--pt-story-stepper-background);
  --app-card-slider-button-border: var(--pt-story-stepper-border);
  --app-card-slider-button-border-radius: var(--pt-story-stepper-border-radius);
  --app-card-slider-button-box-shadow: var(--pt-story-stepper-box-shadow);
  --app-card-slider-button-icon-size: var(--pt-story-stepper-icon-size);
  --app-card-slider-button-icon-color: var(--pt-story-stepper-icon-color);

  --app-card-slider-button-height: var(--pt-story-stepper-height);
  --app-card-slider-button-width: var(--pt-story-stepper-width);
  --app-card-slider-button-top: var(--pt-story-stepper-top);
  // stepper prev button
  --app-card-slider-button-prev-left: var(--pt-story-stepper-prev-left, 0);
  // stepper next button
  --app-card-slider-button-next-right: var(--pt-story-stepper-next-right, 0);

  @include respond-to(sm, up) {
    --app-card-slider-button-height: var(--pt-story-stepper-height-m, var(--pt-story-stepper-height));
    --app-card-slider-button-width: var(--pt-story-stepper-width-m, var(--pt-story-stepper-width));
    --app-card-slider-button-top: var(--pt-story-stepper-top-m, var(--pt-story-stepper-top, 50%));
    // stepper prev button
    --app-card-slider-button-prev-left: var(--pt-story-stepper-prev-left, var(--pt-story-stepper-prev-left, 0));
    // stepper next button
    --app-card-slider-button-next-right: var(--pt-story-stepper-next-right, var(--pt-story-stepper-next-right, 0));
  }

  @include respond-to(lg, up) {
    --app-card-slider-button-height: var(--pt-story-stepper-height-l, var(--pt-story-stepper-height));
    --app-card-slider-button-width: var(--pt-story-stepper-width-l, var(--pt-story-stepper-width));
    --app-card-slider-button-top: var(--pt-story-stepper-top-l, var(--pt-story-stepper-top, 50%));
    // stepper prev button
    --app-card-slider-button-prev-left: var(--pt-story-stepper-prev-left, var(--pt-story-stepper-prev-left, 0));
    // stepper next button
    --app-card-slider-button-next-right: var(--pt-story-stepper-next-right, var(--pt-story-stepper-next-right, 0));
  }
}

.arrows-space {
  height: var(--app-card-slider-button-height);

  @include respond-to(sm, up) {
    height: 0;
  }
}

.slider {
  height: 100%;
}
