@import '../../resources/vars';
@import '../../resources/mixins';

.link {
  z-index: 2;
}

.container {
  display: flex;
  flex-direction: column;

  padding-bottom: 10px;
  gap: 10px;
}
