@import '../resources/vars';
@import '../resources/mixins';

.container {
  height: var(--smart-banner-container-height,  84px);
  padding: var(--smart-banner-container-padding);
  background: var(--app-custom-sb-background);
  top: 0;
  left: 0;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &.ios {
    --app-custom-sb-background: var(--smart-banner-ios-container-background);
    --app-custom-sb-icon-close-background: var(--smart-banner-ios-icon-close-background);
    --app-custom-sb-icon-close-colour: var(--smart-banner-ios-icon-close-colour);
    --app-custom-sb-button-background: var(--smart-banner-ios-button-background);
    --app-custom-sb-button-box-shadow: var(--smart-banner-ios-button-box-shadow);
    --app-custom-sb-button-border: var(--smart-banner-ios-button-border);

    @include rewrite-styled-text-vars(app-custom-sb-heading, smart-banner-ios-heading);
    @include rewrite-styled-text-vars(app-custom-sb-subheading, smart-banner-ios-subheading);
    @include rewrite-styled-text-vars(app-custom-sb-button-label, smart-banner-ios-button-label);
  }

  &.android {
    --app-custom-sb-background: var(--smart-banner-android-container-background);
    --app-custom-sb-icon-close-background: var(--smart-banner-android-icon-close-background);
    --app-custom-sb-icon-close-colour: var(--smart-banner-android-icon-close-colour);
    --app-custom-sb-button-background: var(--smart-banner-android-button-background);
    --app-custom-sb-button-box-shadow: var(--smart-banner-android-button-box-shadow);
    --app-custom-sb-button-border: var(--smart-banner-android-button-border);

    @include rewrite-styled-text-vars(app-custom-sb-heading, smart-banner-android-heading);
    @include rewrite-styled-text-vars(app-custom-sb-subheading, smart-banner-android-subheading);
    @include rewrite-styled-text-vars(app-custom-sb-button-label, smart-banner-android-button-label);
  }
}

.exit {
  margin: 0 6px;
  background: var(--app-custom-sb-icon-close-background);
  border-radius: var(--smart-banner-icon-close-border-radius);
  padding: var(--smart-banner-icon-close-padding);

  svg {
    height: var(--smart-banner-icon-close-size);
    width: var(--smart-banner-icon-close-size);
  }

  path {
    fill: var(--app-custom-sb-icon-close-colour);
  }
}

.image {
  height: var(--smart-banner-image-size, 64px);
  width: var(--smart-banner-image-size, 64px);
  border-radius: var(--smart-banner-image-border-radius, 15px);
  object-fit: var(--smart-banner-image-object-fit, contain);
}

.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--smart-banner-text-container-gap, 5px);
  margin: var(--smart-banner-text-container-margin, 0 10px);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--smart-banner-button-border-radius, 5px);
  padding: var(--smart-banner-button-padding, 0 10px);
  margin: var(--smart-banner-button-margin, 0 10px);
  height: var(--smart-banner-button-height, 32px);
  background: var(--app-custom-sb-button-background);
  box-shadow: var(--app-custom-sb-button-box-shadow);
  border: var(--app-custom-sb-button-border);

  .label {
    @include with-styled-text(app-custom-sb-button-label);
  }
}

.title {
  @include with-styled-text(app-custom-sb-heading);
}

.author, {
  @include with-styled-text(app-custom-sb-subheading);
}
