@import '../../resources/vars';
@import '../../resources/mixins';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  flex: 1;
}
