@import '../../resources/vars';
@import '../../resources/mixins';

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1 0;
  width: 100%;
  padding: var(--lifescore-dataviz-padding);
  gap: var(--lifescore-dataviz-gap);

  @include respond-to(sm, up) {
    padding: var(--lifescore-dataviz-padding-m, var(--lifescore-dataviz-padding));
    gap: var(--lifescore-dataviz-gap-m, var(--lifescore-dataviz-gap));
  }

  @include respond-to(lg, up) {
    padding: var(--lifescore-dataviz-padding-l, var(--lifescore-dataviz-padding));
    gap: var(--lifescore-dataviz-gap-l, var(--lifescore-dataviz-gap));
  }
}

.item {
  display: flex;
  height: fit-content;
  padding: var(--lifescore-dataviz-item-padding);
  gap: var(--lifescore-dataviz-item-gap);
  border-radius: var(--lifescore-dataviz-item-border-radius);
  flex-direction: var(--lifescore-dataviz-item-flex-direction);
  background: var(--app-custom-lifescore-dataviz-item-background);

  &.left {
    --app-custom-lifescore-dataviz-item-background: var(--lifescore-dataviz-left-item-background);
    --app-custom-lifescore-dataviz-iconbg-background: var(--lifescore-dataviz-left-icon-background);
    --app-custom-lifescore-dataviz-icon-color: var(--lifescore-dataviz-left-icon-color);
    --app-custom-lifescore-dataviz-labels-color: var(--lifescore-dataviz-left-label-color);
    --app-custom-lifescore-dataviz-values-color: var(--lifescore-dataviz-left-value-color);
  }

  &.right {
    --app-custom-lifescore-dataviz-item-background: var(--lifescore-dataviz-right-item-background);
    --app-custom-lifescore-dataviz-iconbg-background: var(--lifescore-dataviz-right-icon-background);
    --app-custom-lifescore-dataviz-icon-color: var(--lifescore-dataviz-right-icon-color);
    --app-custom-lifescore-dataviz-labels-color: var(--lifescore-dataviz-right-label-color);
    --app-custom-lifescore-dataviz-values-color: var(--lifescore-dataviz-right-value-color);
  }

  @include respond-to(sm, up) {
    padding: var(--lifescore-dataviz-item-padding-m, var(--lifescore-dataviz-item-padding));
    gap: var(--lifescore-dataviz-item-gap-m, var(--lifescore-dataviz-item-gap));
    flex-direction: var(--lifescore-dataviz-item-flex-direction-m, var(--lifescore-dataviz-item-flex-direction));
  }

  @include respond-to(lg, up) {
    padding: var(--lifescore-dataviz-item-padding-l, var(--lifescore-dataviz-item-padding));
    gap: var(--lifescore-dataviz-item-gap-l, var(--lifescore-dataviz-item-gap));
    flex-direction: var(--lifescore-dataviz-item-flex-direction-l, var(--lifescore-dataviz-item-flex-direction));
  }
}

.wording {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--lifescore-dataviz-words-gap);

  @include respond-to(sm, up) {
    gap: var(--lifescore-dataviz-words-gap-m, var(--lifescore-dataviz-words-gap));
  }

  @include respond-to(lg, up) {
    gap: var(--lifescore-dataviz-words-gap-l, var(--lifescore-dataviz-words-gap));
  }
}

.icon {
  height: fit-content;
  background: var(--app-custom-lifescore-dataviz-iconbg-background);
  border-radius: var(--lifescore-dataviz-iconbg-border-radius);
  padding: var(--lifescore-dataviz-iconbg-padding);

  svg {
    height: var(--lifescore-dataviz-icon-size);
    width: var(--lifescore-dataviz-icon-size);
  }

  path {
    fill: var(--app-custom-lifescore-dataviz-icon-color);
  }

  @include respond-to(sm, up) {
    padding: var(--lifescore-dataviz-iconbg-padding-m, var(--lifescore-dataviz-iconbg-padding));
  }

  @include respond-to(lg, up) {
    padding: var(--lifescore-dataviz-iconbg-padding-l, var(--lifescore-dataviz-iconbg-padding));
  }

}

.label {
  @include with-styled-text(lifescore-dataviz-labels);
  color: var(--app-custom-lifescore-dataviz-labels-color, var(--lifescore-dataviz-labels-color))
}

.value {
  @include with-styled-text(lifescore-dataviz-values);
  color: var(--app-custom-lifescore-dataviz-values-color, var(--lifescore-dataviz-values-color))
}
