@import '../../resources/vars';
@import '../../resources/mixins';


@mixin identifier-styling-option-override ($option) {
  // Container
  --app-custom-ph-identifier-margin: var(--page-header-id-margin-#{$option});
  --app-custom-ph-identifier-margin-m: var(--page-header-id-margin-m-#{$option});
  --app-custom-ph-identifier-margin-l: var(--page-header-id-margin-l-#{$option});
  --app-custom-ph-identifier-padding: var(--page-header-id-padding-#{$option});
  --app-custom-ph-identifier-padding-m: var(--page-header-id-padding-m-#{$option});
  --app-custom-ph-identifier-padding-l: var(--page-header-id-padding-l-#{$option});
  --app-custom-ph-identifier-border: var(--page-header-id-border-#{$option});
  --app-custom-ph-identifier-border-radius: var(--page-header-id-border-radius-#{$option});
  --app-custom-ph-identifier-background: var(--page-header-id-background-#{$option});
  --app-custom-ph-identifier-box-shadow: var(--page-header-id-box-shadow-#{$option});

  // Label
  --app-custom-ph-identifier-label-color: var(--page-header-id-label-colour-#{$option});
  --app-custom-ph-identifier-label-font-family: var(--page-header-id-label-font-family-#{$option});
  --app-custom-ph-identifier-label-font-size: var(--page-header-id-label-font-size-#{$option});
  --app-custom-ph-identifier-label-font-size-m: var(--page-header-id-label-font-size-m-#{$option});
  --app-custom-ph-identifier-label-font-size-l: var(--page-header-id-label-font-size-l-#{$option});
  --app-custom-ph-identifier-label-font-weight: var(--page-header-id-label-font-weight-#{$option});
  --app-custom-ph-identifier-label-font-style: var(--page-header-id-label-font-style-#{$option});
  --app-custom-ph-identifier-label-line-height: var(--page-header-id-label-line-height-#{$option});
  --app-custom-ph-identifier-label-letter-spacing: var(--page-header-id-label-letter-spacing-#{$option});
  --app-custom-ph-identifier-label-text-transform: var(--page-header-id-label-text-transform-#{$option});
  --app-custom-ph-identifier-label-text-align: var(--page-header-id-label-text-align-#{$option});
  --app-custom-ph-identifier-label-text-shadow: var(--page-header-id-label-text-shadow-#{$option});
}
.category {
  width: fit-content;

  // Container
  padding: var(--app-custom-ph-identifier-padding, var(--page-header-category-padding));
  margin: var(--app-custom-ph-identifier-margin, var(--page-header-category-margin));
  border: var(--app-custom-ph-identifier-border, var(--page-header-category-border));
  border-radius: var(--app-custom-ph-identifier-border-radius, var(--page-header-category-border-radius));
  background: var(--app-custom-ph-identifier-background, var(--page-header-category-background));
  box-shadow: var(--app-custom-ph-identifier-box-shadow, var(--page-header-category-box-shadow));

  // Label
  color: var(--app-custom-ph-identifier-label-color, var(--page-header-category-label-colour));
  font-family: var(--app-custom-ph-identifier-label-font-family, var(--page-header-category-label-font-family));
  font-weight: var(--app-custom-ph-identifier-label-font-weight, var(--page-header-category-label-font-weight));
  font-style: var(--app-custom-ph-identifier-label-font-style, var(--page-header-category-label-font-style));
  line-height: var(--app-custom-ph-identifier-label-line-height, var(--page-header-category-label-line-height));
  letter-spacing: var(--app-custom-ph-identifier-label-letter-spacing, var(--page-header-category-label-letter-spacing));
  text-transform: var(--app-custom-ph-identifier-label-text-transform, var(--page-header-category-label-text-transform));
  text-align: var(--app-custom-ph-identifier-label-text-align, var(--page-header-category-label-text-align));
  font-size: var(--app-custom-ph-identifier-label-font-size, var(--page-header-category-label-font-size));
  text-shadow: var(--app-custom-ph-identifier-label-text-shadow, var(--page-header-category-label-text-shadow));

  @include respond-to(sm, up) {
    font-size: var(--app-custom-ph-identifier-label-font-size-m, var(--page-header-category-label-font-size-m));
    margin: var(--app-custom-ph-identifier-margin-m, var(--page-header-category-margin-m, var(--page-header-category-margin)));
    padding: var(--app-custom-ph-identifier-padding-m, var(--page-header-category-padding-m, var(--page-header-category-padding)));
  }

  @include respond-to(lg, up) {
    font-size: var(--app-custom-ph-identifier-label-font-size-l, var(--page-header-category-label-font-size-l));
    margin: var(--app-custom-ph-identifier-margin-l, var(--page-header-category-margin-l, var(--page-header-category-margin)));
    padding: var(--app-custom-ph-identifier-padding-l, var(--page-header-category-padding-l, var(--page-header-category-padding)));
  }
}

// Override with styling options
@for $i from 1 through 5 {
  .option#{$i} {
    @include identifier-styling-option-override(opt-#{$i});
  }
}
