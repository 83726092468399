@import '../../resources/vars';
@import '../../resources/mixins';

.container {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.info {
  display: flex;
  flex-direction: column;
}

.doughnut {
  --app-pie-chart-whole-stroke: var(--vm-points-donut-whole-colour);
  --app-pie-chart-value-stroke: var(--vm-points-donut-progress-colour);
  max-height: var(--vm-points-donut-size);

  max-width: var(--vm-points-donut-size);
}

.title {
  @include with-styled-text(vm-points-card-label);
}

.points {
  @include with-styled-text(vm-points-data-label);
  margin: 0 0 16px;

  span {
    @include with-styled-text(vm-points-data-value);
  }
}

.target {
  @include with-styled-text(vm-points-target-label);
}

.value {
  @include with-styled-text(vm-points-donut-value);
}

.suffix {
  @include with-styled-text(vm-points-donut-suffix);
}
