@import '../../resources/vars';
@import '../../resources/mixins';

.wrapper {
  margin: var(--trust-card-margin);
  max-width: var(--trust-card-max-width);
}

.container {
  background: var(--trust-card-content-container-background);
  border: var(--trust-card-content-container-border);
  padding: var(--trust-card-content-container-padding);
  border-radius: var(--trust-card-content-container-border-radius);
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: var(--trust-card-header-container-background);
  padding: var(--trust-card-header-container-padding);
  border-radius: var(--trust-card-header-container-border-radius);
}

.body {
  display: grid;
  row-gap: var(--trust-card-body-container-gap-row);
  background: var(--trust-card-body-container-background);
  padding: var(--trust-card-body-container-padding);
  border-radius: var(--trust-card-body-container-border-radius);
  border-top: var(--trust-card-body-container-border-top);
}

.image {
  height: var(--trust-card-header-img-size);
  width: var(--trust-card-header-img-size);
  border-radius: var(--trust-card-header-img-border-radius);
}

.partner {
  display: flex;
  flex-direction: column;
  --trust-card-heading-text-padding: 0 0 0 10px;
  padding: var(--trust-card-heading-text-padding);
}

.title {
  @include with-styled-text(trust-card-header-paragraph);
  padding: var(--trust-card-header-paragraph-padding);
}

.name {
  @include with-styled-text(trust-card-header-heading);
  padding: var(--trust-card-header-heading-padding);
}

.item {
  padding: var(--trust-card-item-padding);
  margin: var(--trust-card-item-margin);
}

.info {
  display: flex;
  align-items: center;
  padding: var(--trust-card-item-header-padding);
  margin: var(--trust-card-item-header-margin);
}

.icon {
  svg {
    height: var(--trust-card-item-icon-size);
    width: var(--trust-card-item-icon-size);

    path {
      fill: var(--trust-card-item-icon-colour);
    }
  }
}

.heading {
  @include with-styled-text(trust-card-item-heading);
  padding: var(--trust-card-item-heading-padding);
}

.description {
  @include with-styled-text(trust-card-item-body);
  padding: var(--trust-card-item-body-padding);
}
