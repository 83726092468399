@import '../resources/vars';
@import '../resources/mixins';

.callout {
  display: flex;
  flex-direction: column;

  background: var(--callout-background);
  border: var(--callout-border);
  border-radius: var(--callout-border-radius);
  box-shadow: var(--callout-box-shadow);
  gap: var(--callout-gap);
  padding: var(--callout-padding);

  @include respond-to(md, up) {
    gap: var(--callout-gap-m);
    padding: var(--callout-padding-m);
  }

  @include respond-to(lg, up) {
    gap: var(--callout-gap-l);
    padding: var(--callout-padding-l);
  }
}

.header {
  display: flex;
  align-items: center;
  gap: var(--callout-header-gap);
  padding: var(--callout-header-padding);

  @include respond-to(md, up) {
    gap: var(--callout-header-gap-m);
    padding: var(--callout-header-padding-m);
  }

  @include respond-to(lg, up) {
    gap: var(--callout-header-gap-l);
    padding: var(--callout-header-padding-l);
  }

  .icon {
    background: var(--callout-icon-bg-background);
    border: var(--callout-icon-bg-border);
    border-radius: var(--callout-icon-bg-border-radius);
    padding: var(--callout-icon-bg-padding);

    @include respond-to(md, up) {
      padding: var(--callout-icon-bg-padding-m);
    }

    @include respond-to(lg, up) {
      padding: var(--callout-icon-bg-padding-l);
    }

    svg {
      height: var(--callout-icon-size);
      width: var(--callout-icon-size);
    }

    path {
      fill: var(--callout-icon-color);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--callout-h-colour);
    font-family: var(--callout-h-font-face);
    font-size: var(--callout-h-font-size);
    font-style: var(--callout-h-font-style);
    font-weight: var(--callout-h-font-weight);
    letter-spacing: var(--callout-h-letter-spacing);
    line-height: var(--callout-h-line-height);
    text-align: var(--callout-h-text-align);
    text-transform: var(--callout-h-text-transform);
    margin: var(--callout-h-margin);

    @include respond-to(md, up) {
      font-size: var(--callout-h-font-size-m);
    }

    @include respond-to(lg, up) {
      font-size: var(--callout-h-font-size-l);
    }
  }
}

.description{
  p {
    color: var(--callout-p-colour);
    font-family: var(--callout-p-font-face);
    font-size: var(--callout-p-font-size);
    font-style: var(--callout-p-font-style);
    font-weight: var(--callout-p-font-weight);
    letter-spacing: var(--callout-p-letter-spacing);
    line-height: var(--callout-p-line-height);
    text-align: var(--callout-p-text-align);
    text-transform: var(--callout-p-text-transform);
    margin: var(--callout-p-margin);

    @include respond-to(md, up) {
      font-size: var(--callout-p-font-size-m);
    }

    @include respond-to(lg, up) {
      font-size: var(--callout-p-font-size-l);
    }
  }
}
