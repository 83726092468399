@import '../../resources/vars';
@import '../../resources/mixins';

.carousel {
  --app-height: calc(100dvh - env(safe-area-inset-bottom) - env(safe-area-inset-top));

  --app-default-card-slider-height: 50px; // To not break the calc() function with undefined value.
  --app-card-slider-card-height: calc(var(--app-height) - var(--app-custom-top-nav-height) - var(--app-progress-bar-height) - var(--app-card-slider-button-height));
  --app-card-slider-card-width: calc(var(--app-card-slider-card-height) * var(--pt-story-slides-aspect-ratio));
  --app-card-slider-height: calc(var(--app-height) - var(--app-custom-top-nav-height) - var(--app-progress-bar-height) - var(--app-card-slider-button-height) - var(--story-slide-spacer-bottom, var(--app-default-card-slider-height)));

  @include respond-to(sm, up) {
    --app-card-slider-card-height: calc(var(--app-height) - var(--app-custom-top-nav-height) - var(--app-progress-bar-height-m));
    --app-card-slider-card-width: calc(var(--app-card-slider-card-height) * var(--pt-story-slides-aspect-ratio-m));
    --app-card-slider-height: calc(var(--app-height) - var(--app-custom-top-nav-height) - var(--app-progress-bar-height) - var(--story-slide-spacer-bottom-m, var(--app-default-card-slider-height)));
  }

  @include respond-to(lg, up) {
    --app-card-slider-card-height: calc(var(--app-height) - var(--app-custom-top-nav-height) - var(--app-progress-bar-height-l));
    --app-card-slider-card-width: calc(var(--app-card-slider-card-height) * var(--pt-story-slides-aspect-ratio-l));
    --app-card-slider-height: calc(var(--app-height) - var(--app-custom-top-nav-height) - var(--app-progress-bar-height) - var(--story-slide-spacer-bottom-l, var(--app-default-card-slider-height)));
  }

  position:  relative;
  height: var(--app-card-slider-height, 100%);
}

.carousel-inner {
  margin: var(--app-slider-slick-list-margin);
  overflow-y: visible;
  overflow-x: clip;
  height: 100%;
}

.carousel-items {
  display: flex;
  justify-content: center;
  height: 100%;
}

.hidden-description {
  position: absolute;
  color: transparent;
}

.swipe-wrapper, .swipe-wrapper > div {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.carousel-item {
  width: var(--app-card-slider-card-width, 100%);
  padding: var(--story-slide-padding);

  @include respond-to(sm, up) {
    padding: var(--story-slide-padding-m);
  }

  @include respond-to(lg, up) {
    padding: var(--story-slide-padding-l);
  }
}

.carousel-item[data-active="false"] {
  display: none;
}

.carousel-item[data-active="true"] {
  display: flex;
}

.carousel-item-link {
  color: inherit;
  user-select: none;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background: var(--story-slide-background);
  border-radius: var(--story-slide-border-radius);
  border: var(--story-slide-border);
  box-shadow: var(--story-slide-box-shadow);

  @include respond-to(sm, up) {
    border: var(--story-slide-border-m, var(--story-slide-border));
  }

  @include respond-to(lg, up) {
    border: var(--story-slide-border-l, var(--story-slide-border));
  }
}

.carousel-item[data-animation-direction="prev"] {
  animation: prev 0.5s;
}

.carousel-item[data-animation-direction="next"] {
  animation: next 0.5s;
}

@keyframes next {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes prev {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.carousel-controls {
  box-sizing: border-box;
}

.carousel-control {
  position: absolute;
  z-index: 10;
  height: var(--app-card-slider-button-height);
  width: var(--app-card-slider-button-width);
  background: var(--app-card-slider-button-background);
  border: var(--app-card-slider-button-border);
  border-radius: var(--app-card-slider-button-border-radius);
  box-shadow: var(--app-card-slider-button-box-shadow);

  svg {
    height: var(--app-card-slider-button-icon-size);
    width: var(--app-card-slider-button-icon-size);
  }

  path {
    fill: var(--app-card-slider-button-icon-color);
  }

  &[aria-disabled="true"] {
    path {
      fill-opacity: 0.5;
    }
  }
}

.carousel-control {
  top: var(--app-card-slider-button-top);
}

.carousel-previous-control {
  left: var(--app-card-slider-button-prev-left);
}

.carousel-next-control {
  right: var(--app-card-slider-button-next-right);
}
