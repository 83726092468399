@import '../../resources/vars';
@import '../../resources/mixins';

.container {
  display: flex;
}

.icon {
  background: var(--app-custom-score-indicator-background-off, var(--goal-row-score-indicator-background-off));
  border: var(--app-custom-score-indicator-border-off, var(--goal-row-score-indicator-border-off));
  box-shadow: var(--app-custom-score-indicator-box-shadow-off, var(--goal-row-score-indicator-box-shadow-off));
  border-radius: var(--app-custom-score-indicator-border-radius, var(--goal-row-score-indicator-border-radius));
  padding: var(--app-custom-score-indicator-padding, var(--goal-row-score-indicator-padding));
  width: var(--app-custom-score-indicator-width, var(--goal-row-score-indicator-size));
  height: var(--app-custom-score-indicator-height, var(--goal-row-score-indicator-size));

  @include respond-to(sm, up) {
    --app-default-score-indicator-padding-m: var(--goal-row-score-indicator-padding-m, var(--goal-row-score-indicator-padding));
    padding: var(--app-custom-score-indicator-padding-m, var(--app-default-score-indicator-padding-m));
    width: var(--app-custom-score-indicator-width-m, var(--goal-row-score-indicator-size));
  }

  @include respond-to(lg, up) {
    --app-default-score-indicator-padding-l: var(--goal-row-score-indicator-padding-l, var(--goal-row-score-indicator-padding));
    padding: var(--app-custom-score-indicator-padding-l, var(--app-default-score-indicator-padding-l));
    width: var(--app-custom-score-indicator-width-l, var(--goal-row-score-indicator-size));
  }

  svg {
    display: var(--app-custom-score-indicator-icon-display, var(--goal-row-score-indicator-icon-display, block));
    height: var(--app-custom-score-indicator-icon-size, var(--goal-row-score-indicator-icon-size));
    width: var(--app-custom-score-indicator-icon-size, var(--goal-row-score-indicator-icon-size));
  }

  path {
    fill: var(--app-custom-score-indicator-icon-color-off, var(--goal-row-score-indicator-icon-color-off));
  }

  &.active {
    background: var(--app-custom-score-indicator-background-on, var(--goal-row-score-indicator-background-on));
    border: var(--app-custom-score-indicator-border-on, var(--goal-row-score-indicator-border-on));
    box-shadow: var(--app-custom-score-indicator-box-shadow-on, var(--goal-row-score-indicator-box-shadow-on));

    path {
     fill: var(--app-custom-score-indicator-icon-color-on, var(--goal-row-score-indicator-icon-color-on));
    }
  }
}
