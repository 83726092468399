@import '../resources/vars';
@import '../resources/mixins';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: var(--input-align);

  &:not(:first-child) {
    margin-top: var(--input-spacer);
  }
}

.controller {
  display: flex;
  flex-direction: column;
  max-width: var(--input-group-width);
  align-items: var(--input-align);
  width: 100%;
}

.wrapper {
  align-items: flex-end;
  display: flex;
  width: 100%;
}

.input {
  flex: 1;
}

.button {
  width: 100%;
  max-width: var(--input-btn-width);
  margin-top: var(--input-spacer);
}
