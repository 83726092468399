@import '../resources/vars';
@import '../resources/mixins';

.container {
  --app-input-container-direction: var(--forms-input-container-direction);
  --app-input-container-align-items: var(--forms-input-container-align-items);
  --app-input-container-justify-content: var(--forms-input-container-justify-content);
  --app-input-container-padding: var(--forms-input-container-padding);
  --app-input-container-border-width: var(--forms-input-container-border-width);
  --app-input-container-border-style: var(--forms-input-container-border-style);
  --app-input-container-border-color: var(--forms-input-container-border-color);

  --app-input-label-direction: var(--forms-input-label-direction);
  --app-input-label-justify-content: var(--forms-input-label-justify-content);
  --app-input-label-align-items: var(--forms-input-label-align-items);
  --app-input-label-margin: var(--forms-input-label-margin);

  --app-input-informer-margin: var(--forms-input-informer-margin);

  --app-input-label-order: var(--forms-input-text-order-label);
}

.field {
  @extend %truncated-text;

  order: var(--forms-input-text-order-field);

  @include with-styled-text(forms-input-value);
  background-color: var(--forms-input-background-color);
  border-color: var(--forms-input-border-color);
  border-radius: var(--forms-input-border-radius);
  border-style: var(--forms-input-border-style);
  border-width: var(--forms-input-border-width);
  height: var(--input-height, var(--forms-input-height));
  padding: var(--forms-input-padding);
  width: var(--forms-input-width);

  &::placeholder {
    @include with-styled-text(forms-input-placeholder);
  }

  @include outline-control() {
    @extend %input-reset;
  }
}

.label {
  @include with-styled-text(forms-input-label);

  @include respond-to(sm, up) {
    font-size: var(--forms-input-label-font-size-m, var(--forms-input-label-font-size));
  }

  @include respond-to(lg, up) {
    font-size: var(--forms-input-label-font-size-l, var(--forms-input-label-font-size));
  }
}
