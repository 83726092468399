@import '../resources/vars';
@import '../resources/mixins';

.container {
  background-color: var(--knowledge-item-bg-colour);
  position: relative;

  &::after {
    border-bottom: var(--knowledge-item-keyline);
    content: '';
    display: block;
    max-width: var(--knowledge-item-keyline-max-width);

    @include respond-to(xss) {
      margin: var(--knowledge-item-keyline-margin);
    }

    @at-root :global(.sidebar) & {
      margin: var(--knowledge-item-keyline-margin);
    }
  }

  &:last-child::after {
    border-bottom: var(--knowledge-item-keyline-last);
  }
}

// Alignment
.button {
  align-items: center;
  display: flex;
  position: relative;
  overflow: visible;
}

// Box
.button {
  background-color: var(--knowledge-item-btn-bg-colour);
  box-shadow: var(--knowledge-item-btn-box-shadow);
  max-width: var(--knowledge-item-btn-max-width);
  min-height: var(--knowledge-item-btn-height);
  padding: var(--knowledge-item-btn-padding);

  @include respond-to(xss) {
    margin: var(--knowledge-item-btn-margin);
  }

  @at-root :global(.sidebar) & {
    margin: var(--knowledge-item-btn-margin);
  }
}

// Border
.button {
  border: var(--knowledge-item-btn-border);
  border-radius: var(--knowledge-item-btn-border-radius);

  @at-root .container:first-child & {
    border-top-left-radius: var(--knowledge-item-btn-border-radius-first);
    border-top-right-radius: var(--knowledge-item-btn-border-radius-first);
  }

  @at-root .container:last-child & {
    border-bottom-left-radius: var(--knowledge-item-btn-border-radius-last);
    border-bottom-right-radius: var(--knowledge-item-btn-border-radius-last);
  }
}

// Keyline
.button {
  &::after {
    border-left: var(--knowledge-item-indicator-width) solid var(--knowledge-item-indicator-off-colour);
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    @include respond-to(lg) {
      content: '';
    }
  }

  &:hover::after {
    border-color: var(--knowledge-item-indicator-hover-colour);
  }

  &.selected::after {
    border-color: var(--knowledge-item-indicator-on-colour);
  }
}

.graphics {
  position: var(--knowledge-item-graphics-position, relative);
  margin: var(--knowledge-item-graphics-margin);
  top: var(--knowledge-item-graphics-top);
  right: var(--knowledge-item-graphics-right);
  bottom: var(--knowledge-item-graphics-bottom);
  left: var(--knowledge-item-graphics-left);
  display: var(--knowledge-item-graphics-display);
  order: var(--knowledge-item-graphics-order)
}

.thumbnail-size {
  width: var(--knowledge-item-image-width);
  height: var(--knowledge-item-image-height);
}

.thumbnail {
  position: relative;
  overflow: hidden;
  display: var(--knowledge-item-image-display);
  order: var(--knowledge-item-image-order);
  background: var(--knowledge-item-image-background);
  border: var(--app-custom-knowledge-item-image-border);
  border-radius: var(--knowledge-item-image-border-radius);
  padding: var(--knowledge-item-image-padding);

  @include respond-to(sm, up) {
    padding: var(--knowledge-item-image-padding-m);
  }

  @include respond-to(lg, up) {
    padding: var(--knowledge-item-image-padding-l);
  }
}

.status-icon-size {
  width: var(--knowledge-item-status-icon-container-width);
  height: var(--knowledge-item-status-icon-container-height);
}

.status-icon {
  display: var(--knowledge-item-status-icon-display, flex);
  order: var(--knowledge-item-status-icon-order);
  position: var(--knowledge-item-status-icon-position, absolute);
  top: var(--knowledge-item-status-icon-top, 50%);
  bottom: var(--knowledge-item-status-icon-bottom, auto);
  left: var(--knowledge-item-status-icon-left, 50%);
  right: var(--knowledge-item-status-icon-right, auto);
  transform: translate(-50%, -50%);
  z-index: var(--app-z-index-layer-101);

  background: var(--app-custom-knowledge-item-status-icon-container-background);
  border: var(--app-custom-knowledge-item-status-icon-container-border);

  border-radius: var(--knowledge-item-status-icon-container-border-radius);

  svg {
    height: var(--knowledge-item-status-icon-size);
    width: var(--knowledge-item-status-icon-size);
  }

  path {
    fill: var(--app-custom-knowledge-item-status-icon-color);
  }
}

.labels-container {
  display: flex;
  flex-direction: column;
  flex: 1 0;

  order: var(--knowledge-item-labels-order);

  gap: var(--knowledge-item-labels-gap);
  padding: var(--knowledge-item-labels-padding);

  @include respond-to(sm, up) {
    gap: var(--knowledge-item-labels-gap-m);
    padding: var(--knowledge-item-labels-padding-m);
  }

  @include respond-to(lg, up) {
    gap: var(--knowledge-item-labels-gap-l);
    padding: var(--knowledge-item-labels-padding-l);
  }
}

.label {
  @include with-styled-text(knowledge-item-label);

  @include respond-to(sm, up) {
    font-size: var(--knowledge-item-label-font-size-m);
  }

  @include respond-to(lg, up) {
    font-size: var(--knowledge-item-label-font-size-l);
  }
}

.status-label {
  display: var(--knowledge-item-status-label-display);

  @include with-styled-text(knowledge-item-status-label);
  color: var(--app-custom-knowledge-item-status-label-color);

  @include respond-to(sm, up) {
    font-size: var(--knowledge-item-status-label-font-size-m);
  }

  @include respond-to(lg, up) {
    font-size: var(--knowledge-item-status-label-font-size-l);
  }
}

.action {
  align-items: center;
  display: flex;
  justify-content: center;
}

.action {
  order: var(--knowledge-item-action-order);
  background-color: var(--knowledge-item-action-bg-colour);
  border: var(--knowledge-item-action-bg-border);
  border-radius: var(--knowledge-item-action-bg-border-radius);
  height: var(--knowledge-item-action-bg-width);
  margin: var(--knowledge-item-action-bg-margin);
  width: var(--knowledge-item-action-bg-height);
}

.action {
  svg {
    height: var(--knowledge-item-action-icon-size);
    width: var(--knowledge-item-action-icon-size);
  }

  path {
    fill: var(--knowledge-item-action-icon-color);
  }
}

.description {
  display: var(--knowledge-item-description-label-display);
  @include with-styled-text(knowledge-item-description-label);

  @include respond-to(sm, up) {
    font-size: var(--knowledge-item-description-label-font-size-m);
  }

  @include respond-to(lg, up) {
    font-size: var(--knowledge-item-description-label-font-size-l);
  }
}

.overlay:before {
  position: absolute;
  content:"";
  top: 0;
  width: 100%;
  height: 100%;
  z-index: var(--app-z-index-layer-100);
  background: var(--app-custom-knowledge-item-image-tint);
}

.read-container {
  display: var(--knowledge-item-time-container-display, flex);
  flex-direction: var(--knowledge-item-time-container-direction, row);
  gap: var(--knowledge-item-time-container-gap, 8px);
  align-items: var(--knowledge-item-time-container-align-items, center);
  justify-content: var(--knowledge-item-time-container-justify-content, flex-end);
  padding: var(--knowledge-item-time-container-padding);

  @include respond-to(sm, up) {
    padding: var(--knowledge-item-time-container-padding-m);
  }

  @include respond-to(lg, up) {
    padding: var(--knowledge-item-time-container-padding-l);
  }
}

.read-icon {
  display: var(--knowledge-item-time-icon-container-display);
  padding: var(--knowledge-item-read-icon-container-padding);
  background: var(--knowledge-item-time-icon-container-background);
  border: var(--knowledge-item-time-icon-container-border);
  border-radius: var(--knowledge-item-time-icon-container-border-radius);

  svg {
    width: var(--knowledge-item-time-icon-size);
    height: var(--knowledge-item-time-icon-size);
  }

  path {
    fill: var(--knowledge-item-time-icon-colour);
  }
}

.read-text {
  @include with-styled-text(knowledge-item-time-label);

  @include respond-to(sm, up) {
    font-size: var(--knowledge-item-time-label-font-size-m);
  }

  @include respond-to(lg, up) {
    font-size: var(--knowledge-item-time-label-font-size-l);
  }
}
