@import '../resources/vars';
@import '../resources/mixins';

.text {
  &::after{
    content: var(--app-required-indicator-content);

    @include with-styled-text(forms-required);
    margin: var(--forms-required-margin);
  }

}
