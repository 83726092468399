@import '~@packages/ui-kit/styles/mixins';

@mixin text-zone {
  &.content {
    &.heading {
      color: var(--swatch-content-text-headings);
    }

    &.strong {
      color: var(--swatch-content-text-strong);
    }

    &.subtle {
      color: var(--swatch-content-text-subtle);
    }

    &.link {
      color: var(--swatch-content-text-link);
    }
  }

  &.card {
    &.heading {
      color: var(--swatch-card-text-headings);
    }

    &.strong {
      color: var(--swatch-card-text-strong);
    }

    &.subtle {
      color: var(--swatch-card-text-subtle);
    }

    &.link {
      color: var(--swatch-card-text-link);
    }
  }

  &.hero {
    &.heading {
      color: var(--swatch-hero-text-headings);
    }

    &.strong {
      color: var(--swatch-hero-text-strong);
    }

    &.subtle {
      color: var(--swatch-hero-text-subtle);
    }

    &.link {
      color: var(--swatch-hero-text-link);
    }
  }
}

@mixin rewrite-responsive-styled-text-vars($rewritable-prefix, $prefix, $exclude...) {
  @include rewrite-styled-text-vars($rewritable-prefix, $prefix, $exclude);

  @include respond-to(md) {
    --#{$rewritable-prefix}-font-size-m: var(--#{$prefix}-font-size-m);
  }

  @include respond-to(lg) {
    --#{$rewritable-prefix}-font-size-l: var(--#{$prefix}-font-size-l);
  }
}

@mixin rewrite-styled-text-vars($rewritable-prefix, $prefix, $exclude...) {
  $font-text-properties: color, colour, font-size, font-family, font-weight, font-style, line-height, letter-spacing,
    text-transform, text-align, text-shadow, text-decoration;

  @each $prop in $font-text-properties {
    @if index($exclude, $prop) == null {
      --#{$rewritable-prefix}-#{$prop}: var(--#{$prefix}-#{$prop});
    }
  }
}

$font-text-properties: color, font-size, font-family, font-weight, font-style, line-height, letter-spacing,
text-transform, text-align, text-shadow, text-decoration;

@mixin with-styled-text($prefix, $exclude...) {
  $font-text-properties-map: (
    'color': 'colour'
  );

  @each $prop in $font-text-properties {
    @if index($exclude, $prop) == null {
      $value: map-get($font-text-properties-map, $prop) or $prop;

      #{$prop}: var(--#{$prefix}-#{$value});
    }
  }

  @include respond-to(md, up) {
    font-size: var(--#{$prefix}-font-size-m, var(--#{$prefix}-font-size));
  }

  @include respond-to(lg, up) {
    font-size: var(--#{$prefix}-font-size-l, var(--#{$prefix}-font-size));
  }
}

// This mixin is used to apply the text styling to the element based on the prefix provided.
// The difference is in using proper color property name
@mixin with-styled-text-v2($prefix, $exclude...) {
  @each $prop in $font-text-properties {
    @if index($exclude, $prop) == null {
      #{$prop}: var(--#{$prefix}-#{$prop});
    }
  }

  @include respond-to(md, up) {
    font-size: var(--#{$prefix}-font-size-m, var(--#{$prefix}-font-size));
  }

  @include respond-to(lg, up) {
    font-size: var(--#{$prefix}-font-size-l, var(--#{$prefix}-font-size));
  }
}

// This mixin is used to apply the custom text styling to the element based on the custom and default prefixes provided.
// The difference is in using proper color property name
@mixin with-custom-styled-text-v2($custom-prefix, $default-prefix, $exclude...) {
  @each $prop in $font-text-properties {
    @if index($exclude, $prop) == null {

      #{$prop}: var(--#{$custom-prefix}-#{$prop}, var(--#{$default-prefix}-#{$prop}));
    }
  }

  @include respond-to(md, up) {
    --#{$custom-prefix}-font-size: var(--#{$custom-prefix}-font-size-m, var(--#{$default-prefix}-font-size-m));
  }

  @include respond-to(lg, up) {
    --#{$custom-prefix}-font-size: var(--#{$custom-prefix}-font-size-l, var(--#{$default-prefix}-font-size-l));
  }
}

@mixin with-custom-styled-text($custom-prefix, $default-prefix, $exclude...) {
  $font-text-properties-map: (
      'color': 'colour'
  );

  @each $prop in $font-text-properties {
    @if index($exclude, $prop) == null {
      $value: map-get($font-text-properties-map, $prop) or $prop;

      #{$prop}: var(--#{$custom-prefix}-#{$value}, var(--#{$default-prefix}-#{$value}));
    }
  }

  @include respond-to(md, up) {
    --app-mixin-custom-font-size-m: var(--#{$default-prefix}-font-size-m, var(--#{$custom-prefix}-font-size));
    font-size: var(--#{$custom-prefix}-font-size-m, var(--app-mixin-custom-font-size-m));
  }

  @include respond-to(lg, up) {
    --app-mixin-custom-font-size-l: var(--#{$default-prefix}-font-size-l, var(--#{$custom-prefix}-font-size));
    font-size: var(--#{$custom-prefix}-font-size-l, var(--app-mixin-custom-font-size-l));
  }
}

@mixin with-accounts($property) {
  $accounts: (
    htbISA: --btn-account-htb-isa-color,
    lifetimeISA: --btn-account-lisa-color,
    regularSavings: --btn-account-regular-savings-color,
    cashISA: --btn-account-cash-isa-color,
    stockAndSharesISA: --btn-account-stock-and-shares-isa-color,
    current: --btn-account-current-color,
    others: --btn-account-others-color
  );

  @each $name, $value in $accounts {
    &.#{$name} {
      #{$property}: var(#{$value});
      @content;
    }
  }
}

@mixin with-advert-item-overrides($option){
  //Container overrides
  --app-custom-tl-item-margin: var(--targeted-links-item-#{$option}-margin);
  --app-custom-tl-item-background: var(--targeted-links-item-#{$option}-background);
  --app-custom-tl-item-border: var(--targeted-links-item-#{$option}-border);
  --app-custom-tl-item-border-radius: var(--targeted-links-item-#{$option}-border-radius);
  --app-custom-tl-item-box-shadow: var(--targeted-links-item-#{$option}-box-shadow);
  --app-custom-tl-item-flex-direction: var(--targeted-links-item-#{$option}-flex-direction);
  --app-custom-tl-item-flex-direction-m: var(--targeted-links-item-#{$option}-flex-direction-m);
  --app-custom-tl-item-flex-direction-l: var(--targeted-links-item-#{$option}-flex-direction-l);
  --app-custom-tl-item-padding: var(--targeted-links-item-#{$option}-padding);
  --app-custom-tl-item-padding-m: var(--targeted-links-item-#{$option}-padding-m);
  --app-custom-tl-item-padding-l: var(--targeted-links-item-#{$option}-padding-l);
  --app-custom-tl-item-gap: var(--targeted-links-item-#{$option}-gap);
  --app-custom-tl-item-gap-m: var(--targeted-links-item-#{$option}-gap-m);
  --app-custom-tl-item-gap-l: var(--targeted-links-item-#{$option}-gap-l);
  --app-custom-tl-item-align-items: var(--targeted-links-item-#{$option}-align-items);
  --app-custom-tl-item-align-items-m: var(--targeted-links-item-#{$option}-align-items-m);
  --app-custom-tl-item-align-items-l: var(--targeted-links-item-#{$option}-align-items-l);

  //Cover image overrides
  --app-custom-tl-cover-img-padding: var(--targeted-links-item-cover-image-#{$option}-padding);
  --app-custom-tl-cover-img-padding-m: var(--targeted-links-item-cover-image-#{$option}-padding-m);
  --app-custom-tl-cover-img-padding-l: var(--targeted-links-item-cover-image-#{$option}-padding-l);
  --app-custom-tl-cover-img-height: var(--targeted-links-item-cover-image-#{$option}-height);
  --app-custom-tl-cover-img-height-m: var(--targeted-links-item-cover-image-#{$option}-height-m);
  --app-custom-tl-cover-img-height-l: var(--targeted-links-item-cover-image-#{$option}-height-l);
  --app-custom-tl-cover-img-width: var(--targeted-links-item-cover-image-#{$option}-width);
  --app-custom-tl-cover-img-width-m: var(--targeted-links-item-cover-image-#{$option}-width-m);
  --app-custom-tl-cover-img-width-l: var(--targeted-links-item-cover-image-#{$option}-width-l);
  --app-custom-tl-cover-img-object-fit: var(--targeted-links-item-cover-image-#{$option}-object-fit);

  //Body overrides
  --app-custom-tl-item-body-padding: var(--targeted-links-item-body-#{$option}-padding);
  --app-custom-tl-item-body-padding-m: var(--targeted-links-item-body-#{$option}-padding-m);
  --app-custom-tl-item-body-padding-l: var(--targeted-links-item-body-#{$option}-padding-l);
  --app-custom-tl-item-body-gap: var(--targeted-links-item-body-#{$option}-gap);
  --app-custom-tl-item-body-gap-m: var(--targeted-links-item-body-#{$option}-gap-m);
  --app-custom-tl-item-body-gap-l: var(--targeted-links-item-body-#{$option}-gap-l);

  //Header overrides
  --app-custom-tl-item-header-padding: var(--targeted-links-item-header-#{$option}-padding);
  --app-custom-tl-item-header-padding-m: var(--targeted-links-item-header-#{$option}-padding-m);
  --app-custom-tl-item-header-padding-l: var(--targeted-links-item-header-#{$option}-padding-l);
  --app-custom-tl-item-header-gap: var(--targeted-links-item-header-#{$option}-gap);
  --app-custom-tl-item-header-gap-m: var(--targeted-links-item-header-#{$option}-gap-m);
  --app-custom-tl-item-header-gap-l: var(--targeted-links-item-header-#{$option}-gap-l);
  --app-custom-tl-item-header-justify-content: var(--targeted-links-item-header-#{$option}-justify-content);
  --app-custom-tl-item-header-justify-content-m: var(--targeted-links-item-header-#{$option}-justify-content-m);
  --app-custom-tl-item-header-justify-content-l: var(--targeted-links-item-header-#{$option}-justify-content-l);
  --app-custom-tl-item-header-align-items: var(--targeted-links-item-header-#{$option}-align-items);
  --app-custom-tl-item-header-align-items-m: var(--targeted-links-item-header-#{$option}-align-items-m);
  --app-custom-tl-item-header-align-items-l: var(--targeted-links-item-header-#{$option}-align-items-l);

  //Icon overrides
  --app-custom-tl-item-icon-size: var(--targeted-links-item-icon-#{$option}-size);
  --app-custom-tl-item-icon-colour: var(--targeted-links-item-icon-#{$option}-colour);
  --app-custom-tl-item-icon-bg-padding: var(--targeted-links-item-icon-#{$option}-bg-padding);
  --app-custom-tl-item-icon-bg-border-radius: var(--targeted-links-item-icon-#{$option}-bg-border-radius);
  --app-custom-tl-item-icon-bg-background: var(--targeted-links-item-icon-#{$option}-bg-background);
  --app-custom-tl-item-icon-bg-border: var(--targeted-links-item-icon-#{$option}-bg-border);

  //Heading overrides
  @include rewrite-styled-text-vars(app-custom-tl-item-heading, targeted-links-item-heading-#{$option});
  --app-custom-tl-item-heading-font-size: var(--targeted-links-item-heading-#{$option}-font-size);
  --app-custom-tl-item-heading-font-size-m: var(--targeted-links-item-heading-#{$option}-font-size-m);
  --app-custom-tl-item-heading-font-size-l: var(--targeted-links-item-heading-#{$option}-font-size-l);
  --app-custom-tl-item-heading-padding: var(--targeted-links-item-heading-#{$option}-padding);
  --app-custom-tl-item-heading-padding-m: var(--targeted-links-item-heading-#{$option}-padding-m);
  --app-custom-tl-item-heading-padding-l: var(--targeted-links-item-heading-#{$option}-padding-l);

  //Paragraph overrides
  @include rewrite-styled-text-vars(app-custom-tl-item-p, targeted-links-item-p-#{$option});
  --app-custom-tl-item-p-font-size: var(--targeted-links-item-p-#{$option}-font-size);
  --app-custom-tl-item-p-font-size-m: var(--targeted-links-item-p-#{$option}-font-size-m);
  --app-custom-tl-item-p-font-size-l: var(--targeted-links-item-p-#{$option}-font-size-l);
  --app-custom-tl-item-p-padding: var(--targeted-links-item-p-#{$option}-padding);
  --app-custom-tl-item-p-padding-m: var(--targeted-links-item-p-#{$option}-padding-m);
  --app-custom-tl-item-p-padding-l: var(--targeted-links-item-p-#{$option}-padding-l);

  //Paragraph link overrides
  @include rewrite-styled-text-vars(app-custom-tl-item-p-link, targeted-links-item-p-link-#{$option});
  --app-custom-tl-item-p-link-font-size: var(--targeted-links-item-p-link-#{$option}-font-size);
  --app-custom-tl-item-p-link-font-size-m: var(--targeted-links-item-p-link-#{$option}-font-size-m);
  --app-custom-tl-item-p-link-font-size-l: var(--targeted-links-item-p-link-#{$option}-font-size-l);
  --app-custom-tl-item-p-link-text-decoration: var(--targeted-links-item-p-link-#{$option}-text-decoration);
  --app-custom-tl-item-p-link-color-visited: var(--targeted-links-item-p-link-#{$option}-color-visited);
  --app-custom-tl-item-p-link-text-decoration-visited: var(--targeted-links-item-p-link-#{$option}-text-decoration-visited);
  --app-custom-tl-item-p-link-color-hover: var(--targeted-links-item-p-link-#{$option}-color-hover);
  --app-custom-tl-item-p-link-text-decoration-hover: var(--targeted-links-item-p-link-#{$option}-text-decoration-hover);
  --app-custom-tl-item-p-link-color-active: var(--targeted-links-item-p-link-#{$option}-color-active);
  --app-custom-tl-item-p-link-text-decoration-active: var(--targeted-links-item-p-link-#{$option}-text-decoration-active);


  // Inline image overrides
  --app-custom-tl-inline-img-padding: var(--targeted-links-item-inline-image-#{$option}-padding);
  --app-custom-tl-inline-img-padding-m: var(--targeted-links-item-inline-image-#{$option}-padding-m);
  --app-custom-tl-inline-img-padding-l: var(--targeted-links-item-inline-image-#{$option}-padding-l);
  --app-custom-tl-inline-img-height: var(--targeted-links-item-inline-image-#{$option}-height);
  --app-custom-tl-inline-img-height-m: var(--targeted-links-item-inline-image-#{$option}-height-m);
  --app-custom-tl-inline-img-height-l: var(--targeted-links-item-inline-image-#{$option}-height-l);
  --app-custom-tl-inline-img-width: var(--targeted-links-item-inline-image-#{$option}-width);
  --app-custom-tl-inline-img-width-m: var(--targeted-links-item-inline-image-#{$option}-width-m);
  --app-custom-tl-inline-img-width-l: var(--targeted-links-item-inline-image-#{$option}-width-l);

  //Buttons container overrides
  --app-custom-tl-buttons-gap: var(--targeted-links-item-buttons-#{$option}-gap);
  --app-custom-tl-buttons-gap-m: var(--targeted-links-item-buttons-#{$option}-gap-m);
  --app-custom-tl-buttons-gap-l: var(--targeted-links-item-buttons-#{$option}-gap-l);
  --app-custom-tl-buttons-align: var(--targeted-links-item-buttons-align-#{$option}-items);
  --app-custom-tl-buttons-align-m: var(--targeted-links-item-buttons-align-#{$option}-items-m);
  --app-custom-tl-buttons-align-l: var(--targeted-links-item-buttons-align-#{$option}-items-l);

  //Button overrides
  --app-custom-tl-button-width: var(--targeted-links-item-button-#{$option}-width);
}

@mixin informer-style-override($prefix) {
  // Informer container
  --app-custom-informer-flex-direction: var(--#{$prefix}-direction, row);
  --app-custom-informer-background: var(--#{$prefix}-background);
  --app-custom-informer-padding: var(--#{$prefix}-padding);
  --app-custom-informer-gap: var(--#{$prefix}-gap);
  --app-custom-informer-justify-content: var(--#{$prefix}-justify-content);
  --app-custom-informer-align-items: var(--#{$prefix}-align-items, flex-start);
  --app-custom-informer-border: var(--#{$prefix}-border);
  --app-custom-informer-border-radius: var(--#{$prefix}-border-radius);
  --app-custom-informer-box-shadow: var(--#{$prefix}-box-shadow);
  --app-custom-informer-max-width: var(--#{$prefix}-max-width);
  --app-custom-informer-width: var(--#{$prefix}-width);
  --app-custom-informer-margin: var(--#{$prefix}-margin);

  --app-custom-informer-width-m: var(--#{$prefix}-width-m);
  --app-custom-informer-padding-m: var(--#{$prefix}-padding-m);
  --app-custom-informer-gap-m: var(--#{$prefix}-gap-m);

  --app-custom-informer-width-l: var(--#{$prefix}-width-l);
  --app-custom-informer-padding-l: var(--#{$prefix}-padding-l);
  --app-custom-informer-gap-l: var(--#{$prefix}-gap-l);

  // Informer icon
  --app-custom-informer-icon-background: var(--#{$prefix}-icon-background);
  --app-custom-informer-icon-padding: var(--#{$prefix}-icon-padding);

  --app-custom-informer-icon-padding-m: var(--#{$prefix}-icon-padding-m);

  --app-custom-informer-icon-padding-l: var(--#{$prefix}-icon-padding-l);

  --app-custom-informer-svg-size: var(--#{$prefix}-icon-size);
  --app-custom-informer-svg-border: var(--#{$prefix}-icon-border);
  --app-custom-informer-svg-border-radius: var(--#{$prefix}-icon-border-radius);
  --app-custom-informer-svg-color: var(--#{$prefix}-icon-color);

  // Informer text
  @include rewrite-responsive-styled-text-vars(app-custom-informer-p, #{$prefix}-p);


  // Informer list
  @include rewrite-responsive-styled-text-vars(app-custom-informer-li, #{$prefix}-li);
}

@mixin badge-style($prefix) {
  --app-custom-checklist-item-badge-background: var(--checklist-item-badge-background-#{$prefix}, --checklist-item-badge-background);
  --app-custom-checklist-item-badge-border: var(--checklist-item-badge-border-#{$prefix}, --checklist-item-badge-border);
  --app-custom-checklist-item-badge-border-radius: var(--checklist-item-badge-border-radius-#{$prefix}, --checklist-item-badge-border-radius);
  --app-custom-checklist-item-badge-box-shadow: var(--checklist-item-badge-box-shadow-#{$prefix}, --checklist-item-badge-box-shadow);
  --app-custom-checklist-item-badge-margin: var(--checklist-item-badge-margin-#{$prefix}, --checklist-item-badge-margin);
  --app-custom-checklist-item-badge-padding: var(--checklist-item-badge-padding-#{$prefix}, --checklist-item-badge-padding);
  @include respond-to(sm, up) {
    --app-custom-checklist-item-badge-margin: var(--checklist-item-badge-margin-m-#{$prefix}, --checklist-item-badge-margin-m);
    --app-custom-checklist-item-badge-padding: var(--checklist-item-badge-padding-m-#{$prefix}, --checklist-item-badge-padding-m);
  }
  @include respond-to(lg, up) {
    --app-custom-checklist-item-badge-margin: var(--checklist-item-badge-margin-l-#{$prefix}, --checklist-item-badge-margin-l);
    --app-custom-checklist-item-badge-padding: var(--checklist-item-badge-padding-l-#{$prefix}, --checklist-item-badge-padding-l);
  }
}

@mixin badge-style-label($prefix) {
  @include with-styled-text(checklist-item-badge-label-#{$prefix});
}
