@import '../resources/vars';
@import '../resources/mixins';

.wrapper {
  order: var(--breadcrumb-container-order, -1);
  background: var(--breadcrumb-container-background);
  padding: var(--breadcrumb-container-padding);

  @include respond-to(md) {
    padding: var(--breadcrumb-container-padding-m);
  }

  @include respond-to(lg) {
    padding: var(--breadcrumb-container-padding-l);
  }
}

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 var(--breadcrumb-container-gap);
  width: var(--breadcrumb-container-width);

  @include respond-to(md) {
    gap: 0 var(--breadcrumb-container-gap-m);
    width: var(--breadcrumb-container-width-m);
  }

  @include respond-to(lg) {
    gap: 0 var(--breadcrumb-container-gap-l);
    width: var(--breadcrumb-container-width-l);
  }
}

.link {
  @include with-styled-text(breadcrumb-link-label);
  text-decoration: var(--breadcrumb-link-label-text-decoration);

  &:hover {
    color: var(--breadcrumb-link-label-colour-hover);
    font-style: var(--breadcrumb-link-label-style-hover);
    text-decoration: var(--breadcrumb-link-label-text-decoration-hover);
  }

  &:visited {
    color: var(--breadcrumb-link-label-colour-visited);
    font-style: var(--breadcrumb-link-label-style-visited);
    text-decoration: var(--breadcrumb-link-label-text-decoration-visited);
  }
}

.delimiter {
  @include with-styled-text(breadcrumb-delimiter)
}
