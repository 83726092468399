@import '../resources/vars';
@import '../resources/mixins';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon {
  align-items: center;
  display: flex;
  height: var(--template-checkpoint-icon-size);
  justify-content: center;
  width: var(--template-checkpoint-icon-size);

  &.simple {
    svg {
      height: 100%;
      width: 100%;

      path {
        fill: var(--template-checkpoint-icon-colour);
      }
    }
  }
}

.label {
  color: var(--template-checkpoint-label-colour);
  font-family: var(--template-checkpoint-label-face);
  font-size: var(--template-checkpoint-label-size);
  font-weight: var(--template-checkpoint-label-weight);
  letter-spacing: var(--template-checkpoint-label-letter-spacing);
  line-height: var(--template-checkpoint-label-line-height);
  text-align: var(--template-checkpoint-label-text-align);
  text-transform: var(--template-checkpoint-label-text-transform);
}

// As we have reset the root flex prop with --app-custom-root-flex: 0
// the checkpoint text is outside the window because of absolute position and height 100%
.embedded {
  height: auto !important;
  padding: 48px 0;
}
