$breakpoints: xss xs sm md lg;

$screen: (
  xl: 1400px,
  lg: 1105px,
  md: 920px,
  sm: 768px,
  xs: 568px
);

$screen-max: (
  md: map-get($screen, lg) - 1,
  sm: map-get($screen, md) - 1,
  xs: map-get($screen, sm) - 1,
  xss: map-get($screen, xs) - 1
);

%truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%input-reset {
  appearance: none;
  border-radius: 0;
  outline: none;
}

%checkbox-reset {
  appearance: none;
  outline: none;
}

%scroll-bar-reset {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

%button-reset {
  appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  cursor: pointer;
  font: inherit;

  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  text-align: unset;
  width: auto;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

%preserve-3d {
  transform-style: preserve-3d;
}
