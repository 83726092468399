@import '../resources/vars';
@import '../resources/mixins';

.container {
  --app-input-container-direction: var(--input-checkbox-container-direction);
  --app-input-container-align-items: var(--input-checkbox-container-align-items);
  --app-input-container-justify-content: var(--input-checkbox-container-justify-content);
  --app-input-container-padding: var(--input-checkbox-container-padding);
  --app-input-container-border-width: var(--input-checkbox-container-border-width);
  --app-input-container-border-style: var(--input-checkbox-container-border-style);
  --app-input-container-border-color: var(--input-checkbox-container-border-color);

  --app-input-label-direction: var(--input-checkbox-label-direction);
  --app-input-label-justify-content: var(--input-checkbox-label-justify-content);
  --app-input-label-align-items: var(--input-checkbox-label-align-items);
  --app-input-label-margin: var(--input-checkbox-label-margin);

  --app-input-informer-margin: var(--input-checkbox-informer-margin);

  // Explainer overwrites
  --app-custom-explainer-margin: var(--input-checkbox-option-explainer-margin);

  // set in the apps/firsthomecoach/src/styles/components/inputs/checkbox-group.module.scss
  --app-custom-explainer-label-colour: var(--app-custom-checkbox-option-explainer-color);

  --app-custom-explainer-label-font-family: var(--input-checkbox-option-explainer-font-family);
  --app-custom-explainer-label-font-weight: var(--input-checkbox-option-explainer-font-weight);
  --app-custom-explainer-label-font-style: var(--input-checkbox-option-explainer-font-style);
  --app-custom-explainer-label-line-height: var(--input-checkbox-option-explainer-line-height);
  --app-custom-explainer-label-letter-spacing: var(--input-checkbox-option-explainer-letter-spacing);
  --app-custom-explainer-label-text-transform: var(--input-checkbox-option-explainer-text-transform);
  --app-custom-explainer-label-text-align: var(--input-checkbox-option-explainer-text-align);

  --app-custom-explainer-label-font-size: var(--input-checkbox-option-explainer-font-size);
  --app-custom-explainer-label-font-size-m: var(--input-checkbox-option-explainer-font-size-m);
  --app-custom-explainer-label-font-size-l: var(--input-checkbox-option-explainer-font-size-l);

  // Option image overwrites
  --app-custom-input-image-size: var(--input-checkbox-image-size);
  --app-custom-input-image-border-radius: var(--input-checkbox-image-border-radius);
  --app-custom-input-image-margin: var(--input-checkbox-image-margin);
  --app-custom-input-image-size-m: var(--input-checkbox-image-size-m);
  --app-custom-input-image-margin-m: var(--input-checkbox-image-margin-m);
  --app-custom-input-image-size-l: var(--input-checkbox-image-size-l);
  --app-custom-input-image-margin-l: var(--input-checkbox-image-margin-l);

  // Labels container overwrites
  --app-custom-input-labels-container-display: flex;
  --app-custom-input-labels-container-direction: column;
  --app-custom-input-labels-container-gap: var(--input-checkbox-labels-container-gap);
  --app-custom-input-labels-container-gap-m: var(--input-checkbox-labels-container-gap-m);
  --app-custom-input-labels-container-gap-l: var(--input-checkbox-labels-container-gap-l);

  // Sub-label overwrites
  @include rewrite-styled-text-vars(app-custom-input-sub-label, input-checkbox-sublabel);

}

.input {
  @extend %checkbox-reset;
}

.field {
  @at-root .input:checked + .icon {
    background: var(--app-custom-checkbox-on-bg, var(--input-checkbox-on-background-colour));
    border: var(--app-custom-checkbox-on-border, var(--input-checkbox-on-border));
    border-radius: var(--app-custom-checkbox-on-border-radius, var(--input-checkbox-on-border-radius));
    box-shadow: var(--app-custom-checkbox-on-box-shadow, var(--input-checkbox-on-box-shadow));
    height: var(--app-custom-checkbox-on-bg-size, var(--input-checkbox-on-background-size));
    width: var(--app-custom-checkbox-on-bg-size, var(--input-checkbox-on-background-size));

    svg {
      height: var(--app-custom-checkbox-on-icon-size, var(--input-checkbox-on-icon-size));
      width: var(--app-custom-checkbox-on-icon-size, var(--input-checkbox-on-icon-size));
    }

    path {
      fill: var(--app-custom-checkbox-on-icon-colour, var(--input-checkbox-on-icon-colour));
    }
  }

  @include outline-control('.input:focus + .icon');
}

.icon {
  background: var(--app-custom-checkbox-off-bg, var(--input-checkbox-off-background-colour));
  border: var(--app-custom-checkbox-off-border, var(--input-checkbox-off-border));
  border-radius: var(--app-custom-checkbox-off-border-radius, var(--input-checkbox-off-border-radius));
  box-shadow: var(--app-custom-checkbox-off-box-shadow, var(--input-checkbox-off-box-shadow));
  height: var(--app-custom-checkbox-off-bg-size, var(--input-checkbox-off-background-size));
  width: var(--app-custom-checkbox-off-bg-size, var(--input-checkbox-off-background-size));

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: var(--app-custom-checkbox-off-icon-size, var(--input-checkbox-off-icon-size));
    width: var(--app-custom-checkbox-off-icon-size, var(--input-checkbox-off-icon-size));
  }

  path {
    fill: var(--app-custom-checkbox-off-icon-colour, var(--input-checkbox-off-icon-colour));
  }
}

.label {
  @include with-styled-text(input-checkbox-label);
  color: var(--app-custom-input-label-color);
}

.option-2 {
  @include with-styled-text(input-checkbox-label-opt2);
  color: var(--app-custom-input-label-opt2-color);
}
