@import '../resources/vars';
@import '../resources/mixins';

.container {
  background: var(--app-custom-checklist-item-badge-background, var(--checklist-item-badge-background));
  border: var(--app-custom-checklist-item-badge-border, var(--checklist-item-badge-border));
  border-radius: var(--app-custom-checklist-item-badge-border-radius, var(--checklist-item-badge-border-radius));
  box-shadow: var(--app-custom-checklist-item-badge-box-shadow, var(--checklist-item-badge-box-shadow));
  margin: var(--app-custom-checklist-item-badge-margin, var(--checklist-item-badge-margin));
  padding: var(--app-custom-checklist-item-badge-padding, var(--checklist-item-badge-padding));
  @include respond-to(sm, up) {
    margin: var(--app-custom-checklist-item-badge-margin-m, var(--checklist-item-badge-margin-m));
    padding: var(--app-custom-checklist-item-badge-padding-m, var(--checklist-item-badge-padding-m));
  }
  @include respond-to(lg, up) {
    margin: var(--app-custom-checklist-item-badge-margin-l, var(--checklist-item-badge-margin-l));
    padding: var(--app-custom-checklist-item-badge-padding-l, var(--checklist-item-badge-padding-l));
  }
}

.label {
  @include with-styled-text(checklist-item-badge-label);
}

@for $i from 1 through 5 {
  .container-option#{$i} {
    @include badge-style(opt-#{$i});
  }

  .label-option#{$i} {
    @include badge-style-label(opt-#{$i});
  }
}
