@import '../../resources/vars';
@import '../../resources/mixins';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
}
.image{
  position: absolute;
  width: 100%;
  height: 100%;

  //Aspect ratio should be moved to the parent container
  //aspect-ratio: 16/9;
  flex: 1 1 0;
}
