@import '../../resources/vars';
@import '../../resources/mixins';

.container {
  height: var(--coach-dataviz-height);
  width: var(--coach-dataviz-width);
  padding: var(--coach-dataviz-padding);
  margin: var(--coach-dataviz-margin);
  background: var(--coach-dataviz-background);
  border: var(--coach-dataviz-border);
  border-radius: var(--coach-dataviz-border-radius);
  box-shadow: var(--coach-dataviz-box-shadow);
}
