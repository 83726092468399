@import '../resources/vars';
@import '../resources/mixins';

.block {
  display: flex;
  align-items: center;
  cursor: pointer;

  height: var(--filter-toggle-height);
  width: var(--filter-toggle-width);
  padding: var(--filter-toggle-padding);
  margin: var(--filter-toggle-margin);
  background: var(--filter-toggle-background);
  border: var(--filter-toggle-border);
  border-radius: var(--filter-toggle-border-radius);
  box-shadow: var(--filter-toggle-box-shadow);
}

.label {
  cursor: pointer;

  color: var(--filter-toggle-label-colour);
  size: var(--filter-toggle-label-font-size);
  font-family: var(--filter-toggle-label-font-family);
  font-weight: var(--filter-toggle-label-font-weight);
  font-style: var(--filter-toggle-label-font-style);
  height: var(--filter-toggle-label-line-height);
  letter-spacing: var(--filter-toggle-label-letter-spacing);
  transform: var(--filter-toggle-label-text-transform);
  margin: var(--filter-toggle-label-margin);
  padding: var(--filter-toggle-label-padding);
}

.iconContainer {
  height: var(--filter-toggle-icon-bg-height);
  width: var(--filter-toggle-icon-bg-width);
  padding: var(--filter-toggle-icon-bg-padding);
  margin: var(--filter-toggle-icon-bg-margin);
  background: var(--filter-toggle-icon-bg-background);
  border: var(--filter-toggle-icon-bg-border);
  border-radius: var(--filter-toggle-icon-bg-border-radius);
  box-shadow: var(--filter-toggle-icon-bg-box-shadow);
}

.icon{
  svg {
    width: var(--filter-toggle-icon-size);
    height: var(--filter-toggle-icon-size);
    margin: var(--page-actions-icon-margin);
  }

  path {
    fill: var(--filter-toggle-icon-colour);
  }
}

.active {
  --filter-toggle-background: var(--filter-toggle-background-on);
  --filter-toggle-border: var(--filter-toggle-border-on);
  --filter-toggle-box-shadow: var(--filter-toggle-box-shadow-on);
  --filter-toggle-label-colour: var(--filter-toggle-label-colour-on);
  --filter-toggle-icon-bg-background: var(--filter-toggle-icon-container-background-on);
  --filter-toggle-icon-colour: var(--filter-toggle-icon-colour-on);
}
