@import '../resources/vars';
@import '../resources/mixins';

.table {
  border: 1px solid #000;
  border-collapse: collapse;

  th,
  td {
    border: 1px solid #000;
    padding: 5px;
  }
}

.heading {
  font-weight: bold;
}
