@import '../../resources/vars';
@import '../../resources/mixins';

.content {
  display: flex;
  flex: 1;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }
}

.body {
  flex: 1;
  align-self: center;

  @include respond-to(sm, up) {
    max-width: var(--page-header-body-max-width);
  }
}

.column {
  position: relative;
  align-items: center;
  --app-custom-template-column-start: row-start;
  --app-custom-template-column-end: row-end;

  padding: var(--page-header-spacer-top) 0 var(--page-header-spacer-bottom);

  @include respond-to(sm, up) {
    padding: var(--page-header-spacer-top-m, var(--page-header-spacer-top)) 0
    var(--page-header-spacer-bottom-m, var(--page-header-spacer-bottom));
  }

  @include respond-to(lg, up) {

    padding: var(--page-header-spacer-top-l, var(--page-header-spacer-top)) 0
    var(--page-header-spacer-bottom-l, var(--page-header-spacer-bottom));
  }
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}
