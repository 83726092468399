@import '../resources/vars';
@import '../resources/mixins';

.container {
  display: flex;
  flex: 1;
}
.container-controls {
  padding: var(--content-inner-container-padding);
  gap: var(--content-inner-container-gap);
  flex-direction: var(--content-inner-container-direction);
  align-items: var(--content-inner-container-align-items);
  justify-content: var(--content-inner-container-justify-content);
}

.back-icon {
  background: var(--page-header-back-icon-background);
  border: var(--page-header-back-icon-border);
  border-radius: var(--page-header-back-icon-border-radius);
  padding: var(--page-header-back-icon-padding);
  margin: var(--page-header-back-icon-margin);

  svg {
    height: var(--page-header-back-icon-size);
    width: var(--page-header-back-icon-size);
  }

  path {
    fill: var(--page-header-back-icon-fill);
  }
}
