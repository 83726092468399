@import '../../resources/vars';
@import '../../resources/mixins';

.container {
  background-color: var(--theme-coach-bg-color);
  background-image: var(--theme-coach-bg-image-url);
  background-position: var(--theme-coach-bg-image-position);
  background-repeat: var(--theme-coach-bg-image-repeat);
  background-size: var(--theme-coach-bg-image-size);
  padding-top: var(--coach-padding-top);
  padding-bottom: var(--coach-padding-bottom);

  @include respond-to(md) {
    padding-top: var(--coach-padding-top-m, var(--coach-padding-top));
    padding-bottom: var(--coach-padding-bottom-m, var(--coach-padding-bottom));
  }

  @include respond-to(lg, up) {
    padding-top: var(--coach-padding-top-l, var(--coach-padding-top));
    padding-bottom: var(--coach-padding-bottom-l, var(--coach-padding-bottom));

    --app-custom-template-column-start: var(--app-template-custom-page-coach-column-start);
    --app-custom-template-column-end: var(--app-template-custom-page-coach-column-end);
  }
}

.spinner {
  --app-custom-spinner-icon-padding: var(--coach-reload-padding);
  --app-custom-spinner-icon-size: var(--coach-reload-icon-size);
  --app-custom-spinner-icon-color: var(--coach-reload-icon-color);
}
