@import '../../resources/vars';
@import '../../resources/mixins';

.scorecard {
  gap: var(--goal-scorecard-gap);
  padding: var(--goal-scorecard-padding);
  margin: var(--goal-scorecard-margin);
  background: var(--goal-scorecard-background);
  border: var(--goal-scorecard-border);
  border-radius: var(--goal-scorecard-border-radius);
  box-shadow: var(--goal-scorecard-box-shadow);

  @include respond-to(sm, up) {
    padding: var(--goal-scorecard-padding-m);
    margin: var(--goal-scorecard-margin-m);
  }

  @include respond-to(lg, up) {
    padding: var(--goal-scorecard-padding-l);
    margin: var(--goal-scorecard-margin-l);
  }
}

.progress-container {
  display: flex;
  gap: var(--goal-scorecard-scores-gap);
  padding: var(--goal-scorecard-scores-padding);
  flex-direction: var(--goal-scorecard-scores-flex-direction);

  @include respond-to(sm, up) {
    flex-direction: var(--goal-scorecard-scores-flex-direction-m);
    gap: var(--goal-scorecard-scores-gap-m);
    padding: var(--goal-scorecard-scores-padding-m);
  }

  @include respond-to(lg, up) {
    flex-direction: var(--goal-scorecard-scores-flex-direction-l);
    gap: var(--goal-scorecard-scores-gap-l);
    padding: var(--goal-scorecard-scores-padding-l);
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  flex-direction: var(--goal-scorecard-score-item-flex-direction);
  gap: var(--goal-scorecard-score-item-gap);
  padding: var(--goal-scorecard-score-item-padding);

  &:not(:last-child) {
    margin-bottom: var(--goal-scorecard-score-item-spacer);
  }

  @include respond-to(sm, up) {
    flex-direction: var(--goal-scorecard-score-item-flex-direction-m);
    gap: var(--goal-scorecard-score-item-gap-m);
    padding: var(--goal-scorecard-score-item-padding-m);
  }

  @include respond-to(lg, up) {
    flex-direction: var(--goal-scorecard-score-item-flex-direction-l);
    gap: var(--goal-scorecard-score-item-gap-l);
    padding: var(--goal-scorecard-score-item-padding-l);
  }
}

.icon {
  width: 100%;
  flex: 1 1 0;

  --app-custom-score-indicator-background-off: var(--goal-scorecard-indicator-background-off);
  --app-custom-score-indicator-border-off: var(--goal-scorecard-indicator-border-off);
  --app-custom-score-indicator-box-shadow-off: var(--goal-scorecard-indicator-box-shadow-off);
  --app-custom-score-indicator-icon-color-off: var(--goal-scorecard-indicator-icon-color-off);

  --app-custom-score-indicator-height: var(--goal-scorecard-indicator-height);
  --app-custom-score-indicator-width: var(--goal-scorecard-indicator-width);
  --app-custom-score-indicator-width-m: var(--goal-scorecard-indicator-width-m);
  --app-custom-score-indicator-width-l: var(--goal-scorecard-indicator-width-l);
  --app-custom-score-indicator-padding: var(--goal-scorecard-indicator-padding);
  --app-custom-score-indicator-padding-m: var(--goal-scorecard-indicator-padding-m);
  --app-custom-score-indicator-padding-l: var(--goal-scorecard-indicator-padding-l);
  --app-custom-score-indicator-border-radius: var(--goal-scorecard-indicator-border-radius);

  --app-custom-score-indicator-icon-display: var(--goal-scorecard-indicator-icon-display);
  --app-custom-score-indicator-icon-size: var(--goal-scorecard-indicator-icon-size);

  --app-custom-score-indicator-background-on: var(--goal-scorecard-indicator-background-on);
  --app-custom-score-indicator-border-on: var(--goal-scorecard-indicator-border-on);
  --app-custom-score-indicator-box-shadow-on: var(--goal-scorecard-indicator-box-shadow-on);
  --app-custom-score-indicator-icon-color-on: var(--goal-scorecard-indicator-icon-color-on);
}

.item-label {
  flex: 1 1 100%;

  @include with-styled-text(goal-scorecard-score-item-label);
  margin: var(--goal-scorecard-score-item-label-margin);
  text-align: var(--goal-scorecard-score-item-label-text-align);

  @include respond-to(sm, up) {
    text-align: var(--goal-scorecard-score-item-label-text-align-m);
  }

  @include respond-to(lg, up) {
    text-align: var(--goal-scorecard-score-item-label-text-align-l);
  }
}

.item-label-off {
  color: var(--goal-scorecard-score-item-label-colour-off);
  font-weight: var(--goal-scorecard-score-item-label-font-weight-off);
  font-style: var(--goal-scorecard-score-item-label-font-style-off);
}

.update-container {
  display: flex;
  gap: var(--goals-scores-update-gap);
  padding: var(--goals-scores-update-padding);
  flex-direction: var(--goals-scores-update-flex-direction);
  justify-content: var(--goal-scores-update-justify-content);
  align-items: var(--goal-scores-update-align-items);

  @include respond-to(sm, up) {
    flex-direction: var(--goals-scores-update-flex-direction-m);
    align-items: var(--goal-scores-update-align-items-m);
    justify-content: var(--goal-scores-update-justify-content-m);
    gap: var(--goals-scores-update-gap-m);
    padding: var(--goals-scores-update-padding-m);
  }

  @include respond-to(lg, up) {
    flex-direction: var(--goals-scores-update-flex-direction-l);
    align-items: var(--goal-scores-update-align-items-l);
    justify-content: var(--goal-scores-update-justify-content-l);
    gap: var(--goals-scores-update-gap-l);
    padding: var(--goals-scores-update-padding-l);
  }
}

.update-label {
  @include with-styled-text(goal-scorecard-update-label);
  margin: var(--goal-scorecard-update-label-margin);
  padding: var(--goal-scorecard-update-label-padding);

  @include respond-to(sm, up) {
  text-align: var(--goal-scorecard-update-label-text-align-m);
  }

  @include respond-to(lg, up) {
  text-align: var(--goal-scorecard-update-label-text-align-l);
  }
}

.update-label-off {
  color: var(--goal-scorecard-score-item-label-colour-off);
  font-weight: var(--goal-scorecard-score-item-label-font-weight-off);
  font-style: var(--goal-scorecard-score-item-label-font-style-off);
}
